function StoreOsButton(props: any) {
  const { title, onClick, className, type, disabled } = props;
  return (
    <button  onClick={onClick} className={`${className} max-[360px]:min-w-[100%] bg-primary min-w-[300px] py-[12px] px-[32px] rounded-[60px] text-[16px] font-[500] text-white flex justify-center items-center disabled:opacity-50`} disabled={disabled} type={type}>
      {title}
    </button>
  );
}

export default StoreOsButton;

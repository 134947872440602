import { useContext, useState } from "react";
import lock from "assets/lock.svg";
import useScanBackDocument from "hooks/useScanBackDocument";
import { UserContext } from "context/userContext";
import Layout from "common/layout";
import BackButton from "common/components/backButton";
import CameraComponent from "common/components/camera";
import { generateAuthenticationOptions, generateRegistrationOptions, verifyAuthentication, verifyRegistration } from "services/passkey";
import { startAuthentication, startRegistration } from "@simplewebauthn/browser";
import { useNavigation } from "utils/onNavigate";
import { useToast } from "components/ui/use-toast";
import { extractRoute } from "utils";
import { verifyBackDl } from "services/api";
import { createDocumentUploadIdTypeEnum } from "@privateid/cryptonets-web-sdk";
import {useNavigateWithQueryParams} from "../../../utils/navigateWithQueryParams";

function LoginDl() {
  const {navigateToNextPage} = useNavigation();
  const { navigateWithQueryParams } = useNavigateWithQueryParams();
  const context: any = useContext(UserContext);
  const pageConfig: any = context?.pageConfiguration;
  const [completed, setCompleted] = useState(false);
  const { toast } = useToast();
  const onSuccess = async (result: any) => {
    const { barcodeData } = result;
    context.setUser({ ...context.user, barcodeData });
    const backDocumentData = {
      firstName: barcodeData.firstName,
      lastName: barcodeData.lastName,
      dob: barcodeData.dateOfBirth,
      address: {
        addressLine1: barcodeData.streetAddress1,
        addressLine2: barcodeData.streetAddress2,
        city: barcodeData.city,
        state: barcodeData.state,
        zipCode: barcodeData.postCode,
        country: barcodeData.issuingCountry,
        idDocumentNumber: barcodeData.customerId,
      },
    };

    const payload = {
      barcodeHash64_string: result?.barcodeData?.barcodeHash64_string,
      type: createDocumentUploadIdTypeEnum.drivers_license
    }
    context.setUser({ ...context.user, backDocumentData });
    setCompleted(true);
    const resultBackDl: any = await verifyBackDl(context.user?._id, payload);
    if (resultBackDl?.verified) {
      setTimeout(() => {
        if (context.loginOption === "accountRecovery") {
          onAuthenticatePasskey();
        } else {
          context?.setSuccessMessage("Authentication successful!");
          navigateToNextPage("", "/success", "/face-login");
        }
      }, 1000);
    } else {
      setTimeout(() => {
        navigateToNextPage("", "/failed", "/face-login");
      }, 1000);
    }
  };

  const loadPageConfiguration = () => {
    const currentRouteIndex = context?.configuration?.appPages?.findIndex(
      (item: any) => item?.currentRoute === '/'
    );
    const activeIndex = currentRouteIndex;
    context?.setPageIndex(activeIndex);
    const pageConfiguration = extractRoute(context?.configuration, activeIndex);
    context?.setPageConfiguration(pageConfiguration);
  };

  const onFailPasskey = () => {
    localStorage.removeItem('uuid');
    localStorage.removeItem('user');
    toast({
      variant: "destructive",
      description: "There was some issue generate with passkey.",
    });
    loadPageConfiguration()
    navigateWithQueryParams('/')
  }

  const onAuthenticatePasskey = async () => {
    try {
      const uuid = JSON.parse(localStorage.getItem("uuid") ?? "{}");
      const response = await generateAuthenticationOptions(uuid);
      if (response?.challenge) {
        let asseResp;
        try {
          asseResp = await startAuthentication(response);
        } catch (error) {
          onGeneratePasskey();
          console.log({ error });
        }
        const verificationJSON = await verifyAuthentication({ asseResp, uuid });
        const alreadyEnroll = verificationJSON?.verified &&
          context?.user?.alreadyEnrolled &&
          pageConfig?.type === "enrollPasskey";
        if (verificationJSON?.verified) {
          context.setSuccessMessage("Success! Your account is created");
          if (alreadyEnroll) {
            context?.setSuccessMessage("Authentication successful!");
            navigateToNextPage("", "/success", "/login-dl");
          } else if (context?.configuration?.isKantara) {
            navigateToNextPage();
          } else if (pageConfig) {
            context?.setSuccessMessage("Authentication successful!");
            navigateToNextPage("", "/success", "/generate-passkey");
          } else {
            navigateToNextPage("", "/success", "/generate-passkey");
          }
        } else {
          onGeneratePasskey();
        }
      } else {
        onGeneratePasskey();
      }
    } catch (e) {
      onGeneratePasskey();
    }
  };
  const onGeneratePasskey = async () => {
    const uuid = JSON.parse(localStorage.getItem("uuid") ?? "{}");
    let response;
    try {
      response = await generateRegistrationOptions(uuid);
    } catch (error: any) {
      onFailPasskey();
      return;
    }
    let attResp;
    try {
      const opts = response;
      attResp = await startRegistration(opts);
    } catch (error: any) {
      onFailPasskey();
      return;
    }
    const verificationJSON = await verifyRegistration({ attResp, uuid });
    if (verificationJSON?.verified) {
      context.setSuccessMessage("Authentication successful!");
      context?.setSuccessMessage("Authentication successful!");
      navigateToNextPage("", "/success", "/face-login");
    }
  };

  const { scanBackDocument, scannedCodeData } = useScanBackDocument(
    onSuccess
  ) as any;
  const onCameraReady = () => {
    scanBackDocument();
  };
  return (
    <Layout>
      <div className="px-10 py-8 max-md:p-[20px] max-md:pt-[20px]">
        <div className="flex justify-between relative max-md:p-0">
          <BackButton />
          <div className="bg-backgroundLightGray py-[5px] px-[15px] text-[12px] rounded-[20px] flex items-center m-auto">
            <img src={lock} alt="lock" className="mr-[5px]" />
            {pageConfig?.title || 'Images never leave this device'}
          </div>
          <div></div>
        </div>
        <div className="mt-[50px] rounded-[20px] flex flex-col items-center justify-center">
          <CameraComponent
            backDl={true}
            onCameraReady={onCameraReady}
            message={scannedCodeData?.status_message}
            progress={scannedCodeData?.status_message === "Success" ? 100 : 0}
            scanCompleted={completed}
          />
        </div>
      </div>
    </Layout>
  );
}

export default LoginDl;

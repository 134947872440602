export const CHARLIE_FLOW = {
  primaryColor: "#1A4C92",
  name: "charlie",
  projectId: "charlie",
  locale: "en",
  apiKey: null,
  productGroupId: "charlie",
  cryptonetsUrl: "https://api.prodv2.cryptonets.ai",
  orchestrationUrl: "https://api.orchestration.private.id",
  uploadToServer: false,
  env: {
    REACT_APP_API_KEY: "0000000000000000test",
    REACT_APP_API_URL: "https://api.cvsauth.com/node",
    REACT_APP_API_URL_WASM: "https://api.cvsauth.com/node",
    REACT_APP_ORCHESTRATION_API_URL: "https://orchestration.cvsauth.com",
    REACT_APP_API_ORCHESTRATION: "https://api.orchestration.private.id",
  },
  consumerButtons: false,
  bannerWidth: "150px",
  successCall: true,
  ciba: null,
  liveness: true,
  byPassUser: null,
  redirectUrl: null,
  bgColor: null,
  logo: null,
  bannerImage:
    "https://privateid-cms.s3.us-east-1.amazonaws.com/large_photoo_min_982114d852.png",
  appPages: [
    {
      title: null,
      subheading: null,
      enable: true,
      currentRoute: "/",
      buttonText: null,
      additionalText: null,
      accountText: null,
      registerText: null,
      options: [
        // {
        //   id: 971,
        //   title: "Consumer Registration",
        //   value: "register",
        //   enable: true,
        //   type: "Workforce",
        //   image: null,
        // },
        {
          id: 980,
          title: "Face Registration",
          value: "generate-uuid",
          enable: true,
          type: "Workforce",
          image: null,
        },
        {
          id: 978,
          title: "Active Authentication",
          value: "continuous_authentication",
          enable: true,
          type: "Workforce",
          image: null,
        },
        // {
        //   id: 1034,
        //   title: "Login with OTP/PIN",
        //   value: "face-login-otp",
        //   enable: true,
        //   type: "Workforce",
        //   image: null,
        // },
      ],
    },
    {
      title: "User consent",
      buttonText: "Continue",
      enable: false,
      currentRoute: "/store-os/user-consent",
      consentBody: [
        {
          type: "paragraph",
          children: [
            {
              bold: true,
              text: "MODEL CLAUSE FOR DEMO ENVIRONMENT ID SCAN:",
              type: "text",
            },
          ],
        },
        {
          type: "paragraph",
          children: [
            {
              text: "",
              type: "text",
            },
          ],
        },
        {
          type: "paragraph",
          children: [
            {
              text: "* You certify that you reside in one of the following states: Alaska, Arkansas, Colorado, Connecticut, Delaware, Florida, Georgia, Idaho, Indiana, Iowa, Kansas, Kentucky, Louisiana, Maryland, Massachusetts, Michigan, Minnesota, Mississippi, Missouri, Montana, Nevada, New York, North Dakota, Ohio, Oklahoma, Pennsylvania, South Carolina, South Dakota, Tennessee, Texas, Utah, Vermont, Washington, Washington DC, West Virginia, Wisconsin, or Wyoming.",
              type: "text",
            },
          ],
        },
        {
          type: "paragraph",
          children: [
            {
              text: "",
              type: "text",
            },
          ],
        },
        {
          type: "paragraph",
          children: [
            {
              bold: true,
              text: "MODEL CLAUSES FOR OPERATIONAL ENVIRONMENT: ",
              type: "text",
            },
          ],
        },
        {
          type: "paragraph",
          children: [
            {
              text: "",
              type: "text",
            },
          ],
        },
        {
          type: "list",
          format: "unordered",
          children: [
            {
              type: "list-item",
              children: [
                {
                  text: "If you choose to capture your selfie using the Private Identity application, your selfie will be processed by a neural network running in your browser that compares your face to your Photo ID in a few milliseconds. This process is fast and accurate, and does not keep or share your selfie.\n",
                  type: "text",
                },
              ],
            },
            {
              type: "list-item",
              children: [
                {
                  text: "The biometric data is only captured and processed on your device, and is deleted within one second. Private Identity never transmits, receives, stores, manages or discloses your biometric data.",
                  type: "text",
                },
              ],
            },
          ],
        },
        {
          type: "list",
          format: "unordered",
          children: [
            {
              type: "list-item",
              children: [
                {
                  text: "Only encrypted data is sent to the Private Identity servers. Private Identity then confirms with third-party partners whether or not you have reached the identity assurance threshold required to create an account.",
                  type: "text",
                },
              ],
            },
          ],
        },
        {
          type: "list",
          format: "unordered",
          children: [
            {
              type: "list-item",
              children: [
                {
                  text: "Only encrypted data is sent to the Private Identity servers. Private Identity then confirms with third-party partners whether or not you have reached the identity assurance threshold required to create an account.",
                  type: "text",
                },
              ],
            },
          ],
        },
        {
          type: "list",
          format: "unordered",
          children: [
            {
              type: "list-item",
              children: [
                {
                  text: "This app is certified compliant with the IEEE 2410-2021 Standard for Biometric Privacy. No biometric template is created, processed, stored, transmitted, or received.",
                  type: "text",
                },
              ],
            },
          ],
        },
        {
          type: "list",
          format: "unordered",
          children: [
            {
              type: "list-item",
              children: [
                {
                  text: "I acknowledge I am over 21 years of age, all information I provided is accurate, and I am prohibited from allowing any other person to access or use my verified Private Identity account.",
                  type: "text",
                },
              ],
            },
          ],
        },
        {
          type: "list",
          format: "unordered",
          children: [
            {
              type: "list-item",
              children: [
                {
                  text: "I agree to register for an account using my face images and my US state issued driving license or identity card. Private Identity, and our third-party enrollment and identity proofing service providers IDEMIA and CentralAMS, may share, use and maintain the images and information you provide, and the information on file with other third-party service providers to further verify your identity, to protect against or prevent actual or potential fraud or unauthorized use of the Service, and to establish, maintain and authenticate your verified digital identity, for the duration of our business relationship.",
                  type: "text",
                },
              ],
            },
          ],
        },
        {
          type: "list",
          format: "unordered",
          children: [
            {
              type: "list-item",
              children: [
                {
                  text: "I agree to receive email and SMS messages for the purpose of registering and maintaining an account.",
                  type: "text",
                },
              ],
            },
          ],
        },
        {
          type: "list",
          format: "unordered",
          children: [
            {
              type: "list-item",
              children: [
                {
                  text: "I have read and accepted the Private Identity LLC ",
                  type: "text",
                },
                {
                  url: "https://github.com/openinfer/PrivateIdentity/wiki/Terms-of-Use",
                  type: "link",
                  children: [
                    {
                      text: "Terms of Use",
                      type: "text",
                    },
                  ],
                },
                {
                  text: " and ",
                  type: "text",
                },
                {
                  url: "https://github.com/openinfer/PrivateIdentity/wiki/Privacy-Policy",
                  type: "link",
                  children: [
                    {
                      text: "Privacy Policy",
                      type: "text",
                    },
                  ],
                },
                {
                  text: ", CentralAMS ",
                  type: "text",
                },
                {
                  url: "https://www.centralams.com/terms-of-use/",
                  type: "link",
                  children: [
                    {
                      text: "Terms of Use",
                      type: "text",
                    },
                  ],
                },
                {
                  text: " and ",
                  type: "text",
                },
                {
                  url: "https://www.centralams.com/privacy-policy/",
                  type: "link",
                  children: [
                    {
                      text: "Privacy Policy",
                      type: "text",
                    },
                  ],
                },
                {
                  text: ", and the IDEMIA ",
                  type: "text",
                },
                {
                  url: "https://na.idemia.com/terms/",
                  type: "link",
                  children: [
                    {
                      text: "Terms of Use",
                      type: "text",
                    },
                  ],
                },
                {
                  text: " and ",
                  type: "text",
                },
                {
                  url: "https://na.idemia.com/privacy/",
                  type: "link",
                  children: [
                    {
                      text: "Privacy Policy",
                      type: "text",
                    },
                  ],
                },
                {
                  text: "",
                  type: "text",
                },
              ],
            },
          ],
        },
      ],
      acknowledgeText:
        "I acknowledge that I have read, understood and agree to Private ID’s Terms and Privacy Policy",
      logo: null,
    },
    {
      title: "Personal details",
      subheading: "Please enter the required information ",
      buttonText: "Next",
      currentRoute: "/user-info",
      enable: true,
      sendMsg: false,
      logo: null,
      inputs: [
        {
          id: 1001,
          name: "phone",
          placeholder: "Enter your phone number *",
          type: "phone",
          enable: true,
          icon: null,
        },
      ],
    },
    {
      title: "Take a selfie to verify your identity",
      subheading: "On the next screen, hold your phone at eye level",
      currentRoute: "/store-os/face-scan-intro",
      buttonText: "Start",
      enable: true,
      type: null,
      havingText: " Having problems?",
      switchText: "Switch to Phone",
      faceInstructions: [
        {
          id: 1013,
          heading: "Take off glasses",
          text: "Ensure nothing covers your face",
          image:
            "https://privateid-cms.s3.us-east-1.amazonaws.com/no_hat_965e7772bf.svg",
        },
        {
          id: 1015,
          heading: "Ensure good lighting",
          text: "Your face isn't backlit by a light source",
          image:
            "https://privateid-cms.s3.us-east-1.amazonaws.com/sun_b88caf5166.svg",
        },
        {
          id: 1014,
          heading: "On-Device Processing",
          text: "No facial image leaves this device",
          image:
            "https://privateid-cms.s3.us-east-1.amazonaws.com/no_Face_9dae1a634e.png",
        },
      ],
    },
    {
      currentRoute: "/store-os/facial-scan",
      status: null,
      enable: true,
      type: null,
      havingText: "Having problems?",
      switchText: "Switch to mobile",
      title: "Image will never leave this device",
    },
    // {
    //   title: "Security Challenge",
    //   buttonText: "Save",
    //   enable: true,
    //   currentRoute: "/challenge",
    //   challengeQuestions: [
    //     {
    //       id: 1,
    //       label: "Who is your favorite artist?",
    //       value: "favorite-artist",
    //       image: null,
    //     },
    //     {
    //       id: 3,
    //       label: "What is your least favorite subject in school?",
    //       value: "favorite-subject",
    //       image: null,
    //     },
    //     {
    //       id: 2,
    //       label: "What is your favorite musical instrument?",
    //       value: "favorite-musical",
    //       image: null,
    //     },
    //   ],
    // },
    // {
    //   title: "Create your Passkey ",
    //   subheading:
    //     "Speed up your sign in next time by creating a passkey on this device..",
    //   buttonText: "Create a passkey",
    //   enable: true,
    //   currentRoute: "/generate-passkey",
    //   secondaryButton: null,
    //   type: null,
    //   image: null,
    // },
    {
      currentRoute: "/store-os/waiting",
      status: "Please wait a sec, we’re verifying your identity..",
      enable: true,
      type: null,
      havingText: null,
      switchText: null,
      title: null,
    },
    {
      message: "Success! Your account is secure",
      buttonText: "Sign Out",
      currentRoute: "/success",
      enable: true,
      type: null,
      secondaryButton: "Return to options",
    },
    {
      message: "Account Creation Failed",
      buttonText: null,
      currentRoute: "/failure",
      enable: true,
      type: null,
      secondaryButton: "Return to homepage",
    },
    {
      currentRoute: "/store-os/facial-scan-login",
      status: null,
      enable: true,
      type: "login",
      havingText: null,
      switchText: null,
      title: "Image will never leave this device",
    },
    {
      message: "Authentication failed!",
      buttonText: "Back to home",
      currentRoute: "/failed",
      enable: true,
      type: "login",
      secondaryButton: null,
    },
    {
      message: "Authentication successful!",
      buttonText: "Sign out",
      currentRoute: "/success",
      enable: true,
      type: "login",
      secondaryButton: "Back to home",
    },
    {
      title: "Use your FIDO2® Passkey to Sign In",
      subheading: null,
      enable: true,
      currentRoute: "/login-passkey",
      type: "login",
      image: null,
    },
    {
      title: "To continue, use your smartphone",
      currentRoute: "/switch-device",
      enable: true,
      subheading:
        "To avoid problems with the quality of your verification, please continue on mobile and do not close this tab until verification is done.",
      continueText: null,
      qrCode: [],
    },
    {
      title: "Enter your Card PIN",
      subheading: "Enter your PIN to Register",
      enable: true,
      currentRoute: "/verify-pin",
      type: "register",
      errorMessage: null,
      forgotMessage: null,
      buttonText: null,
      image: null,
    },
    {
      currentRoute: "/face-login-otp",
      status: null,
      enable: true,
      type: null,
      havingText: null,
      switchText: null,
      title: null,
    },
  ],
  footer: [],
};

import { useContext, useEffect, useState } from "react";
import lock from "assets/lock.svg";
import useCameraPermissions from "hooks/useCameraPermissions";
import { UserContext } from "context/userContext";
import Layout from "common/layout";
import BackButton from "common/components/backButton";
import CameraComponent from "common/components/camera";
import useContinuousPredict from "hooks/useContinuousAuthenticationPredict";
import { useToast } from "components/ui/use-toast";
import storeOs from "assets/storeOs.png";
import "react-range-slider-input/dist/style.css";
import { Button } from "components/ui/button";
import { sliceUuid } from "utils";
import {
  // setContinuousPredictDelay,
  setStopLoopContinuousAuthentication,
} from "@privateid/cryptonets-web-sdk";
import SwitchUserModal from "common/components/switchUserModal";
import ContinuousAuthSuccess from "common/components/continuousAuthComponents/success";
import ContinuousAuthForm from "common/components/continuousAuthComponents/form";
import FaceLoginOtp from "common/loginOtp";

let invalidCount = 0;
let notEnrolled = 0;
const maxSeconds = 300; // 5 minutes in seconds
let retry = 1;
let interval: string | number | NodeJS.Timeout;
function ContinuousAuthenticationV2() {
  const [user, setUser] = useState<any>({});
  const { toast } = useToast();
  const context: any = useContext(UserContext);
  const pageConfig: any = context?.pageConfiguration;
  const { isCameraGranted } = useCameraPermissions(() => {});
  const [predictSuccess, setPredictSuccess] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState("green");
  const [range, setRange] = useState([2, 40]);
  const [seconds, setSeconds] = useState(range[1]);
  const [isActive, setIsActive] = useState(false);
  const [isInValidSession, setIsInValidSession] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isSigninForm, setIsSigninForm] = useState(false);
  const [hideCamera, setHideCamera] = useState(false);
  const [signinData, setSignInData] = useState<any>({});
  const [showSwitchUser, setSwitchUser] = useState(false);
  const [currentUuid, setCurrentUuid] = useState("");
  const [isAnimation, setIsAnimation] = useState(false);
  const [isPinScreen, setIsPinScreen] = useState(false);
  const pinScreen =
    isPinScreen || localStorage.getItem("isPinScreen") === "true";
  const [config, setConfig] = useState<{ value: string; retries?: string }>({
    value: "afterInterval",
    retries: "3",
  });
  const uuid = JSON.parse(localStorage.getItem("uuid") || "{}");
  const localUser = JSON.parse(localStorage.getItem("user") || "{}");
  const handleFailureCase = () => {};
  useEffect(() => {
    if (!pinScreen) {
      localStorage.removeItem("uuid");
    }
  }, [pinScreen]);

  useEffect(() => {
    if (pinScreen && uuid?.length > 0) {
      localStorage.setItem("uuid", JSON.stringify(localUser?.uuid || "{}"));
      setHideCamera(true);
      setPredictSuccess(true);
    }
  }, [pinScreen]);

  const handlePredictSuccess = async (result: any) => {
    switch (result.status) {
      case -1: {
        localStorage.removeItem("uuid");
        notEnrolled++;
        handleFailureCase();
        if (notEnrolled >= 5) {
          notEnrolled = 0;
        }
        setHideCamera(false);
        return true;
      }
      case -100: {
        localStorage.removeItem("uuid");
        handleFailureCase();
        invalidCount++;
        setHideCamera(false);
        return true;
      }
      case 10:
      case 0: {
        setProgress(100);
        invalidCount = 0;
        notEnrolled = 0;
        setUser(result);
        return false;
      }
      default: {
        handleFailureCase();
        return false;
      }
    }
  };

  const toggleAnimation = () => {
    setIsAnimation((prevAnimation) => !prevAnimation);
  };

  const handleUnknownUserLogic = (resultUuid: string) => {
    if (config?.value === "silentLogout") {
      toggleAnimation();
      localStorage.setItem("uuid", JSON.stringify(resultUuid || {}));
    } else if (config?.value === "immediately") {
      setProgress(0);
      setSwitchUser(false);
      setIsInValidSession(true);
      localStorage.removeItem("uuid");
      retry = 0;
      onSessionLogout();
    } else {
      if (config?.retries && retry === Number(config?.retries)) {
        setStopLoopContinuousAuthentication(true);
        retry = 0;
        setSwitchUser(true);
        setCurrentUuid(resultUuid);
      } else if (!showSwitchUser) {
        toast({
          description: `New User Detected: ${sliceUuid(resultUuid)}`,
          variant: "customVariant",
        });
        retry++;
      }
    }
  };

  const onSessionLogout = () => {
    setHideCamera(false);
    setPredictSuccess(false);
    setIsActive(false);
    setTimeout(() => {
      setIsInValidSession(false);
    }, 2000);
    clearInterval(interval);
    continuousPredictUser();
  };

  const continuousOnNotFoundAndFailure = () => {
    setProgress(0);
    setUser(null);
  };

  // continuous predict hook
  const {
    faceDetected,
    predictUser: continuousPredictUser,
    continuousPredictMessage,
    predictData,
  } = useContinuousPredict(
    undefined,
    handlePredictSuccess,
    continuousOnNotFoundAndFailure,
    continuousOnNotFoundAndFailure,
    range[0] * 1000
  );

  const onPredict = (predictData: any) => {
    const resultUuid = predictData?.puid;
    const localUuid = uuid?.length && uuid;
    if (
      localUuid === undefined
        ? false
        : resultUuid && resultUuid?.length && resultUuid !== localUuid
    ) {
      handleUnknownUserLogic(resultUuid);
      return;
    } else if (resultUuid) {
      localStorage.setItem("uuid", JSON.stringify(resultUuid || {}));
      setHideCamera(true);
      setPredictSuccess(true);
    }
  };

  useEffect(() => {
    onPredict(predictData);
  }, [predictData]);

  useEffect(() => {
    if (isActive && seconds > 0) {
      if (seconds < 10 && range[1] > 20) {
        setBackgroundColor("#CC0000");
      }
      interval = setInterval(() => {
        setSeconds((seconds) => seconds - 1); // Decrement the seconds
      }, 1000);
    } else if (seconds === 0) {
      localStorage.removeItem("uuid");
      setIsActive(false); // Stop the timer when it reaches 0
      clearInterval(interval);
      setUser({});
      setPredictSuccess(false);
      setHideCamera(false);
    }
    return () => clearInterval(interval);
  }, [isActive, seconds]);

  useEffect(() => {
    if (!faceDetected && !isActive) {
      setIsActive(true);
      setSeconds(range[1]);
    } else if (faceDetected && !isInValidSession) {
      setIsActive(false); // Stop the timer when it reaches 0
      clearInterval(interval);
      setSeconds(range[1]);
      setBackgroundColor("green");
      setPredictSuccess(true);
    }
  }, [faceDetected, isActive, range[1], isInValidSession]);

  const onCameraReady = () => {
    continuousPredictUser();
  };

  const onRangeChange = (event: React.SyntheticEvent | Event | any) => {
    if (event[0] > event[1]) {
      toast({
        variant: "destructive",
        description: "Delay cannot exceed timeout",
      });
      return false;
    } else {
      setSeconds(event[1]);
      setBackgroundColor("green");
      setRange(event);
    }
  };

  const handleThumbDragEnd = (event: React.SyntheticEvent | Event | any) => {
    // setContinuousPredictDelay(range[0] * 1000);
    setIsActive(false); // Stop the timer when it reaches 0
    clearInterval(interval);
    continuousPredictUser();
  };

  const handleRangeDragEnd = (event: React.SyntheticEvent | Event | any) => {
    // setContinuousPredictDelay(range[0] * 1000);
    setIsActive(false); // Stop the timer when it reaches 0
    clearInterval(interval);
    continuousPredictUser();
  };

  const onSwitchUser = () => {
    localStorage.setItem("uuid", JSON.stringify(currentUuid || {}));
    setTimeout(() => {
      setStopLoopContinuousAuthentication(false);
      continuousPredictUser();
    }, 500);
    setSwitchUser(false);
    setProgress(0);
    setSwitchUser(false);
    setIsInValidSession(false);
    toggleAnimation();
  };

  const onChange = (e: { target: { name: string; value: string } }) => {
    const name = e?.target?.name;
    const value = e?.target?.value;
    setSignInData({ ...signinData, [name]: value });
  };

  const onVerifyOtp = (user: any) => {
    setSeconds(range[1]);
    setBackgroundColor("green");
    localStorage.setItem("uuid", JSON.stringify(user?.uuid || {}));
    setHideCamera(true);
    setPredictSuccess(true);
  };

  const onCameraFail = () => {
    setIsPinScreen(true);
  };

  return (
    <div
      style={{ backgroundColor: user?.guid ? "#ededed" : "#ededed" }}
      className={"h-[100vh] max-md:p-[10px] overflow-x-hidden"}
    >
      <Layout
        removeHeight={!isCameraGranted || predictSuccess}
        backgroundColor={predictSuccess ? "" : "#fff"}
        logo={storeOs}
        removeBorder={predictSuccess}
        heightOverride={predictSuccess}
        shadow={true}
        hideFooter={true}
      >
        {predictSuccess && uuid?.length && uuid ? (
          <ContinuousAuthSuccess
            setConfig={setConfig}
            config={config}
            animation={isAnimation}
            backgroundColor={backgroundColor}
            seconds={seconds}
            predictSuccess={predictSuccess}
            range={range}
            onRangeChange={onRangeChange}
            maxSeconds={maxSeconds}
            handleThumbDragEnd={handleThumbDragEnd}
            handleRangeDragEnd={handleRangeDragEnd}
            setIsAnimation={setIsAnimation}
          />
        ) : isSigninForm ? (
          <ContinuousAuthForm
            setIsSigninForm={setIsSigninForm}
            onChange={onChange}
            signinData={signinData}
          />
        ) : null}
        {pinScreen && !isSigninForm && !hideCamera && !uuid?.length ? (
          <FaceLoginOtp
            continuousAuth={true}
            onComplete={(user: any) => onVerifyOtp(user)}
            cameraReady={() => continuousPredictUser()}
          />
        ) : (
          <div
            className={`px-10 py-8 max-md:p-[20px] max-md:pt-[20px] ${
              !isSigninForm && !hideCamera && !uuid?.length ? "block" : "hidden"
            }`}
          >
            <div className="flex justify-between relative">
              <BackButton isCamera />
              <div className="bg-backgroundLightGray py-[5px] px-[15px] text-[12px] rounded-[20px] flex items-center m-auto">
                <img src={lock} alt="lock" className="mr-[5px]" />
                {pageConfig?.title || "Biometrics never leave this device"}
              </div>
              <Button
                onClick={() => {
                  setIsSigninForm(true);
                }}
                className="max-md:hidden text-[13px] p-[15px] py-[7px] h-[unset] text-white bg-primary rounded-[24px]  hover:opacity-90 hover:bg-primary absolute right-[-30px] top-[-5px]"
              >
                Login with username
              </Button>
            </div>
            <div className="mt-[50px] rounded-[20px] flex flex-col items-center justify-center">
              <CameraComponent
                faceCamera={true}
                onCameraReady={onCameraReady}
                progress={progress}
                message={continuousPredictMessage}
                onSuccess={() => {}}
                attempt={continuousPredictMessage ? 1 : 0}
                cameraFail={onCameraFail}
              />
              <Button
                onClick={() => {
                  setIsSigninForm(true);
                }}
                className=" text-[13px] p-[15px] py-[7px] h-[unset] text-white bg-primary rounded-[24px]  hover:opacity-90 hover:bg-primary hidden max-md:block"
              >
                Login with username
              </Button>
            </div>
          </div>
        )}
      </Layout>
      <SwitchUserModal
        showSwitchUser={showSwitchUser}
        onSwitchUser={onSwitchUser}
      />
    </div>
  );
}

export default ContinuousAuthenticationV2;

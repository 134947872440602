import { useContext, useEffect, useState } from "react";
import Lottie from "lottie-react";
import {
  verifyIdWithSession,
  verifySessionTokenV2,
} from "@privateid/cryptonets-web-sdk";
import faceID from "Animations/5-Verify/JSONs/Store-Os-Face_ID.json";
import { Label } from "components/ui/label";
import { useNavigateWithQueryParams } from "utils/navigateWithQueryParams";
import { UserContext } from "context/userContext";
import { issueCredentials } from "services/vc-dock";
import { getFirstRequirement } from "utils";
import { embeddings } from "constant";
import config from "config";
import { useNavigation } from "utils/onNavigate";
import StoreOsLayout from "../layout";
import { registerStoreOs } from "services/storeOs";

type Props = {};
let loaded = false;
const StoreOsWaiting = (props: Props) => {
  const context: any = useContext(UserContext);
  const pageConfig: any = context?.pageConfiguration;
  const [percentage, setPercentage] = useState(0);
  const { navigateWithQueryParams } = useNavigateWithQueryParams();
  const { navigateToNextPage } = useNavigation();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onVerify = async () => {
    loaded = true;
    if (context?.isGenerateUuid) {
      const payload = {
        api_key: "store4cca2ffae08ff3a",
        employeeId: "UserRXDtest38",
        password: "La8223#@",
        embeddings: embeddings,
      };
      const result: any = await registerStoreOs(payload);
      loaded = false;
      if (result?.uuid) {
        context.setSuccessMessage("Success! Your account is created");
        navigateWithQueryParams("/");
      }
      return;
    }
    await verifyIdWithSession({
      sessionToken: context?.tokenParams,
    });
    const verifyTokenRes = await verifySessionTokenV2({
      sessionToken: context?.tokenParams,
    });
    enum tokenStatus {
      PENDING = "PENDING",
      SUCCESS = "SUCCESS",
      FAILURE = "FAILURE",
      REQUIRES_INPUT = "REQUIRES_INPUT",
    }
    if (verifyTokenRes.status === tokenStatus.SUCCESS) {
      // Success
      loaded = false;
      context.setSuccessMessage("Success! Your account is created");
      if (context?.configuration?.isKantara) {
        // navigateToNextPage();
      } else if (pageConfig) {
        localStorage.setItem("user", JSON.stringify(verifyTokenRes));
        // navigateToNextPage();
        navigateWithQueryParams("/store-os/success");
      } else {
        navigateWithQueryParams("/store-os/success");
      }
      await issueVC(verifyTokenRes.user, true);
    } else if (verifyTokenRes.status === tokenStatus.FAILURE) {
      loaded = false;
      if (pageConfig) {
        navigateToNextPage("", "/store-os/failed", "/waiting");
      } else {
        navigateWithQueryParams("/store-os/failed");
      }
    } else if (verifyTokenRes.status === tokenStatus.REQUIRES_INPUT) {
      getRequirements(verifyTokenRes?.dueRequirements);
    } else if (verifyTokenRes.status === tokenStatus.PENDING) {
      loaded = false;
      if (pageConfig) {
        navigateToNextPage("", "/store-os/failed", "/waiting");
      } else {
        navigateWithQueryParams("/store-os/failed");
      }
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (percentage <= 99) {
        setPercentage((prevPercentage) => prevPercentage + 1);
        if (percentage >= 99) {
          clearInterval(intervalId);
        }
      }
    }, 50);
    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, [percentage]);

  useEffect(() => {
    setTimeout(() => {
      if (!loaded) {
        onVerify();
      }
    }, 2000);
  }, []);

  const getRequirements = async (requirement: any) => {
    const requirementStep = await getFirstRequirement(requirement, context);
    loaded = false;
    switch (requirementStep) {
      case "requestSSN9":
        return pageConfig
          ? navigateToNextPage("", "/ssn", "/waiting")
          : navigateWithQueryParams("/ssn");
      case "requireResAddress":
        return pageConfig
          ? navigateToNextPage("", "/address", "/waiting")
          : navigateWithQueryParams("/address");
      case "requestScanID":
        return pageConfig
          ? navigateToNextPage("", "/drivers-licence-intro", "/waiting")
          : navigateWithQueryParams("/drivers-licence-intro");
      default:
        break;
    }
  };

  const issueVC = async (userId: string, fullInformation: boolean) => {
    try {
      await issueCredentials(userId, fullInformation);
    } catch (e) {
      console.log({ e }, "error issueVC");
    }
  };

  return (
    <StoreOsLayout isCard>
      <div className="p-10 max-md:p-[20px]">
        <div
          className="h-[580px] flex justify-center items-center flex-col"
          style={{
            backgroundImage: `url("/verifying-bg.svg")`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
          }}
        >
          <div className="flex justify-center items-center">
            <Lottie
              loop={true}
              autoplay={true}
              animationData={faceID}
              style={{ height: 200, width: 200 }}
            />
          </div>
          {/* <Label className="text-primary text-[28px] font-[700] mt-6">
            {percentage}%
          </Label> */}
          <Label className="text-[28px] font-[500] text-primaryText w-[90%] mt-3 max-[360px]:text-[20px]">
            {pageConfig?.status || (
              <>
                Capture successful!
                <br />
                Registering for Facial Scan...
              </>
            )}
          </Label>
        </div>
      </div>
    </StoreOsLayout>
  );
};

export default StoreOsWaiting;

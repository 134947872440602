import closeModal from "assets/closeModal.svg";
import Createticket from "assets/createTicket.svg";
import { useState } from "react";

function ConfirmModal({ setIsModalOpen, isModalOpen, title, setActiveTab, setActive }: any) {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const onClose = () => {
    setIsModalOpen(!isModalOpen);
    setIsSubmitted(false);
  };
  return (
    <div className="fixed left-0 top-0 w-[100%] h-[100%] flex items-center justify-center">
      <div
        className="bg-[#00000080] fixed top-0 left-0 w-[100%] h-[100%] z-[99]"
        onClick={() => onClose()}
      />
      {!isSubmitted ? (
        <div className="w-[350px] bg-[#fff] rounded-[20px] relative z-[9999] p-[20px]">
          <img
            src={closeModal}
            alt="close"
            className="cursor-pointer absolute right-[20px] top-[20px] w-[20px]"
            onClick={() => setIsModalOpen(!isModalOpen)}
          />
          <div className="mt-[40px] h-[51px]">
          <img
            src={Createticket}
            alt="MyDocIcon"
            className="m-auto"
          />
          </div>
          <h4 className="font-[500] text-[30px] text-[#262626] text-center mt-[20px]">
            Confirm Your Order
          </h4>
          <p className="mt-[20px] text-center text-[#262626] mb-[20px]">
            {title}
          </p>
          <button
            className="h-[40px] bg-[#F6F5F6] border border-[#767676] rounded-[60px] mb-[18px] w-full text-[#262626] hover:bg-[#FFFFFF] hover:border-[#004D99]"
            onClick={() => setIsSubmitted(true)}
          >
            {" "}
            Submit
          </button>
          <button
            onClick={() => setIsModalOpen(!isModalOpen)}
            className="h-[40px] bg-[#F6F5F6] border border-[#767676] rounded-[60px] mb-[18px] w-full text-[#262626] hover:bg-[#FFFFFF] hover:border-[#004D99]"
          >
            {" "}
            Back
          </button>
        </div>
      ) : (
        <div className="w-[400px] h-[430px] bg-[#fff] rounded-[20px] relative z-[9999] p-[20px]">
          <img
            src={closeModal}
            alt="close"
            className="cursor-pointer absolute right-[20px] top-[20px] w-[20px]"
            onClick={() => onClose()}
          />
           <div className="mt-[40px] h-[51px]">
          <img
            src={Createticket}
            alt="MyDocIcon"
            className="m-auto"
          />
          </div>
          <h4 className="font-[500] text-[30px] text-[#262626] text-center mt-[20px]">
            Ticket submitted
          </h4>
          <p className="mt-[20px] text-center text-[#262626] mb-[20px]">
            Your order has been successfully submitted. Your reference number is{" "}
            <b>INC358353</b>
          </p>
          <p className="mt-[20px] text-center text-[#262626] mb-[20px]">
            In-stock items are shipped within 2 business days.
          </p>
          <button
            className="h-[40px] bg-[#F6F5F6] border border-[#767676] rounded-[60px] mb-[18px] w-full text-[#262626] hover:bg-[#FFFFFF] hover:border-[#004D99]"
            onClick={() => onClose()}
          >
            {" "}
            Create New Ticket
          </button>
          <button
            onClick={() => {
              setIsModalOpen(!isModalOpen);
              setIsSubmitted(false);
              setActiveTab("myStore");
              setActive("home");
            }}
            className="h-[40px] bg-[#F6F5F6] border border-[#767676] rounded-[60px] mb-[18px] w-full text-[#262626] hover:bg-[#FFFFFF] hover:border-[#004D99]"
          >
            {" "}
            I’m Done
          </button>
        </div>
      )}
    </div>
  );
}

export default ConfirmModal;

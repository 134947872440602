import userGrey from "assets/me.svg";
import helthGrey from "assets/storeos.svg";
import calendarGrey from "assets/clipboard.svg";
import storeOs from "assets/storeOs.png";
import { Button } from "components/ui/button";
import { extractRoute } from "utils";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "context/userContext";
import { useNavigation } from "utils/onNavigate";
import Layout from "common/layout";
import menu from "assets/menu.svg";
import myreport from "assets/myreport.png";
import search from "assets/search.svg";
import notification from "assets/notification.svg";
import home from "assets/home.svg";
import rxc1 from "assets/rxc1.svg";
import ticket from "assets/ticket.svg";
import inventry from "assets/inventry.svg";
import health from "assets/health.svg";
import clockstore from "assets/clockstore.svg";
import reports from "assets/reports.svg";
import rx2 from "assets/rx2.svg";
import allApp from "assets/allApp.svg";
import Qp from "assets/QP.png";
import RcHome from "assets/rcHome.svg";
import ArrowIcon from "assets/arrowRight.svg";
import pnp from "assets/pnp.svg";
import lock from "assets/lock.svg";
import CameraComponent from "common/components/camera";
import useFaceLoginWithLivenessCheck from "hooks/useFaceLoginWithLiveness";
import { useToast } from "components/ui/use-toast";
import { meData, myShiftData, storeData } from "constant";
import CreateTicket from "./createTicket";

function StoreOs() {
  const context: any = useContext(UserContext);
  const [active, setActive] = useState("home");
  const [isProduction, setIsProduction] = useState(false);
  const [isPredict, setIsPredict] = useState(false);
  const [showCamera, setShowCamera] = useState(false);
  const { toast } = useToast();
  const [activeTab, setActiveTab] = useState("");
  const configuration: any = context?.configuration;
  const { navigateToNextPage } = useNavigation();
  const [completed, setCompleted] = useState(false);
  const uuid = JSON.parse(localStorage.getItem("uuid") || "{}");
  const loadPageConfiguration = () => {
    const currentRouteIndex = configuration?.appPages?.findIndex(
      (item: any) => item?.currentRoute === "/"
    );
    const activeIndex = currentRouteIndex;
    context?.setPageIndex(activeIndex);
    const pageConfiguration = extractRoute(configuration, activeIndex);
    context?.setPageConfiguration(pageConfiguration);
  };

  const handleClick = () => {
    setIsProduction(true);
  };
  const onLogout = () => {
    localStorage.removeItem("uuid");
    localStorage.removeItem("user");
    context.setLoginOption("");
    loadPageConfiguration();
    navigateToNextPage("", "/", "/continuous-authentication-v2");
  };
  const now = new Date();

  const currentTime = now.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  }); // Example: "8:20 PM"

  const currentDate = now.toLocaleDateString("en-US");

  const onHome = () => {
    setActive("home");
    setActiveTab("me");
    setIsProduction(false);
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === "Enter" && isProduction) {
      setIsPredict(true);
    }
  };
  const onSuccess = async () => {
    context.setUser({
      ...context.user,
      uuid: loginPUID,
      guid: loginGUID,
    });
    toast({
      variant: "success",
      description: "User successfully predicted.",
    });
    setIsPredict(false);
    setCompleted(false);
  };

  const onStatus = () => {};
  const onPredictFail = () => {
    if (context?.configuration?.isKantara) {
      navigateToNextPage("", "/predict-fail", "/predict-scan");
    }
  };

  const onRetryFail = () => {
    setShowCamera(true);
  };
  const {
    faceLoginWithLiveness,
    faceLoginWithLivenessMessage,
    faceLoginData,
    faceLoginResponseStatus,
    faceLoginInputImageData,
    loginGUID,
    loginPUID,
    tries,
  } = useFaceLoginWithLivenessCheck(
    () => {},
    onStatus,
    50,
    true,
    onPredictFail,
    onRetryFail
  );

  const onCameraReady = () => {
    faceLoginWithLiveness();
  };

  // useEffect(() => {
  //   // Attach the event listener to the window
  //   window.addEventListener("keydown", handleKeyDown);

  //   // Cleanup the event listener when the component unmounts
  //   return () => {
  //     window.removeEventListener("keydown", handleKeyDown);
  //   };
  // }, [isProduction]);
  useEffect(() => {
    if (loginGUID && loginPUID) {
      setCompleted(true);
    }
  }, [faceLoginWithLivenessMessage]);

  if (!uuid?.length) {
    onLogout();
    return <></>;
  }

  const onTabChange = (tabName: string) => {
    if (["pnp", "rxc1", "createTicket", "recentTicket", "myReports"].includes(tabName)) {
      setActive(tabName);
    } else {
      setActive("home");
    }
    setActiveTab(tabName);
  };

  const _renderScreen = () => {
    return (
      <>
        <div
          className={`relative mt-[-20px] ${
            ["myStore", "myShift"]?.includes(activeTab)
              ? "max-md:mt-[40px]"
              : "max-md:mt-[10px]"
          } `}
        >
          {["myStore", "myShift", "me"]?.includes(activeTab) && (
            <div className="flex mb-[20px] max-md:mb-[10px]">
              <div
                onClick={onHome}
                className={`flex items-center justify-center text-[20px] font-[700] ${
                  activeTab === "me"
                    ? "text-[#fff] border-[#118738] bg-[#118738]"
                    : "text-[#118738] border-[#118738] bg-transparent"
                } min-w-[80px] border px-[25px] py-[10px] cursor-pointer rounded-[50px] mr-[21px] max-md:px-[10px] max-md:min-w-[unxet]`}
              >
                Me
              </div>
              <div
                onClick={() => setActiveTab("myShift")}
                className={`flex items-center justify-center text-[20px] font-[700] min-w-[80px] border ${
                  activeTab === "myShift"
                    ? "text-[#fff] border-[#1171E6] bg-[#1171E6]"
                    : "text-[#1171E6] border-[#1171E6] bg-transparent"
                } px-[25px] py-[10px] cursor-pointer rounded-[50px] mr-[21px] max-md:px-[10px] max-md:min-w-[unxet]`}
              >
                My Shift
              </div>
              <div
                onClick={() => setActiveTab("myStore")}
                className={`flex items-center justify-center text-[20px] font-[700] ${
                  activeTab === "myStore"
                    ? "text-[#fff] border-[#E07800] bg-[#E07800]"
                    : "text-[#E07800] border-[#E07800] bg-transparent"
                }  min-w-[80px] border  px-[25px] py-[10px] cursor-pointer rounded-[50px] mr-[21px] max-md:px-[10px] max-md:min-w-[unxet]`}
              >
                My Store
              </div>
            </div>
          )}
          {active === "home" && activeTab === "" ? (
            <div
              className={`bg-[#fff] p-[20px] rounded-[20px] cardShadow h-[170px] max-md:h-[100px] max-md:mt-[50px]`}
            >
              <p className="text-[#262626] text-[22px] font-[400] text-left">
                Welcome,{" "}
                <span className="text-[#1A4C92]">
                  {/* {uuid?.length > 0 && sliceUuid(uuid)} */}
                  Vanteya
                </span>
              </p>
              <div className="bg-[#949494] h-[1px] w-full mt-[2px]" />
              <h5 className="text-[#CC0000] mt-[20px] text-[28px] text-left">
                Exciting news!
              </h5>
              <p className="text-[14px] text-left">
                You can now access 25 apps without the need to log in again.
              </p>
            </div>
          ) : (
            <div
              className={`bg-[#fff] p-[20px] rounded-[20px] cardShadow h-[150px] max-md:h-[100px] ${
                activeTab === "myShift"
                  ? "max-md:mt-[20px]"
                  : "max-md:mt-[50px]"
              } flex items-center justify-center`}
            >
              <p className="text-[#262626] text-[28px] font-[500]">
                Welcome,{" "}
                <span className="text-[#1A4C92]">
                  {/* {uuid?.length > 0 && sliceUuid(uuid)} */}
                  Vanteya
                </span>
              </p>
            </div>
          )}

          {activeTab === "myShift" ? (
            <div className="flex items-center justify-between mt-[30px] flex-wrap  max-md:block mb-[20px]">
              {myShiftData?.map((item) => {
                return (
                  <div
                    onClick={() => onTabChange(item?.type)}
                    className="bg-[#fff] cursor-pointer p-[15px] rounded-[20px] shadow-lg h-[142px] mb-[20px] max-md:h-[100px] w-[23%] smallCardOs  max-md:w-[100%]  max-md:mb-[30px] flex flex-col items-center justify-center"
                  >
                    <img
                      src={item?.image}
                      className="w-[120px] max-md:w-[190px] max-md:h-[40px] m-auto mb-[10px] h-[50px] object-contain mt-[0px]"
                      alt="user"
                    />
                    <p className="text-[#262626] text-[17px] font-[500] text-center max-md:text-[16px] leading-6">
                      {item?.label}
                    </p>
                  </div>
                );
              })}
            </div>
          ) : activeTab === "myStore" ? (
            <div className="flex items-center justify-between mt-[30px] flex-wrap  max-md:block mb-[20px]">
              {storeData?.map((item) => {
                return (
                  <div
                    onClick={() => onTabChange(item?.type)}
                    className="bg-[#fff] cursor-pointer p-[15px] rounded-[20px] shadow-lg h-[142px] mb-[20px] max-md:h-[100px] w-[23%] smallCardOs  max-md:w-[100%]  max-md:mb-[30px] flex flex-col items-center justify-center"
                  >
                    <img
                      src={item?.image}
                      className="w-[120px] max-md:w-[190px] max-md:h-[40px] m-auto mb-[10px] h-[50px] object-contain mt-[0px]"
                      alt="user"
                    />
                    <p className="text-[#262626] text-[17px] font-[500] text-center max-md:text-[16px] leading-6">
                      {item?.label}
                    </p>
                  </div>
                );
              })}
            </div>
          ) : activeTab === "me" ? (
            <div className="flex items-center justify-between mt-[30px] flex-wrap  max-md:block mb-[20px]">
              {meData?.map((item) => {
                return (
                  <div
                    onClick={() => onTabChange(item?.type)}
                    className="bg-[#fff] cursor-pointer p-[15px] rounded-[20px] shadow-lg h-[142px] mb-[20px] max-md:h-[100px] w-[23%] smallCardOs  max-md:w-[100%]  max-md:mb-[30px] flex flex-col items-center justify-center"
                  >
                    <img
                      src={item?.image}
                      className="w-[120px] max-md:w-[190px] max-md:h-[40px] m-auto mb-[10px] h-[50px] object-contain mt-[0px]"
                      alt="user"
                    />
                    <p className="text-[#262626] text-[17px] font-[500] text-center max-md:text-[16px] leading-6">
                      {item?.label}
                    </p>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="flex items-center justify-between mt-[30px]  max-md:block mb-[20px]">
              <div
                onClick={() => setActiveTab("me")}
                className="bg-[#fff] cursor-pointer p-[20px] rounded-[20px] shadow-lg h-[172px] max-md:h-[100px] w-[30%] smallCardOs  max-md:w-[100%]  max-md:mb-[30px] flex flex-col items-center justify-center"
              >
                <img
                  src={userGrey}
                  className="w-[220px] max-md:w-[190px] max-md:h-[40px] m-auto mb-[10px] h-[80px] object-contain mt-[0px]"
                  alt="user"
                />
                <p className="text-[#262626] text-[22px] font-[500] text-center max-md:text-[16px]">
                  Me
                </p>
              </div>
              <div
                onClick={() => setActiveTab("myShift")}
                className="bg-[#fff] cursor-pointer p-[20px] rounded-[20px] shadow-lg h-[172px] max-md:h-[100px] w-[30%] smallCardOs  max-md:w-[100%]  max-md:mb-[30px] flex flex-col items-center justify-center"
              >
                <img
                  src={calendarGrey}
                  className="w-[220px] max-md:w-[190px] max-md:h-[40px] m-auto mb-[10px] h-[80px] object-contain mt-[0px]"
                  alt="calendar"
                />
                <p className="text-[#262626] text-[22px] font-[500] text-center max-md:text-[16px]">
                  My Shift
                </p>
              </div>
              <div
                onClick={() => setActiveTab("myStore")}
                className="bg-[#fff] cursor-pointer p-[20px] rounded-[20px] shadow-lg h-[172px] max-md:h-[100px] w-[30%] smallCardOs  max-md:w-[100%]  max-md:mb-[30px] flex flex-col items-center justify-center"
              >
                <img
                  src={helthGrey}
                  className="w-[220px] max-md:w-[190px] max-md:h-[40px] m-auto mb-[10px] h-[80px] object-contain mt-[0px]"
                  alt="health"
                />
                <p className="text-[#262626] text-[22px] font-[500] text-center max-md:text-[16px]">
                  My Store
                </p>
              </div>
            </div>
          )}
        </div>
      </>
    );
  };
  const renderCreateTicket = () => {
    return (
      <CreateTicket
        setActiveTab={setActiveTab}
        setActive={setActive}
        active={active}
      />
    );
  };

  return (
    <>
      {["recentTicket", "rxc1", "createTicket"]?.includes(active) ? (
        ""
      ) : ["myReports", "pnp"]?.includes(activeTab) ? (
        ""
      ) : (
        <Button
          className="w-[130px] bg-[#FFFFFF] border-2 !border-[#CFCECE] fixed right-8 top-5 max-md:top-[2.2rem] flex items-center max-md:absolute max-md:right-[20px] border-solid border-primary rounded-[24px] text-[14px] hover:opacity-90 hover:bg-[#fff] text-[#575757] hover:text-[#575757] max-md:w-[100%] max-md:px-[10px] max-md:text-[13px] max-md:max-w-[100px] ml-[10px]"
          onClick={onLogout}
        >
          <img src={ArrowIcon} alt="ArrowIcon" className="mr-[8px] mt-[1px]" />{" "}
          Sign Out
        </Button>
      )}

      <div
        style={{ backgroundColor: active === "rxc1" ? "#E8E9FC" : activeTab === "myReports" ? "#D7D7D7" :  "#ededed" }}
        className={"h-[100vh] max-md:p-[10px] overflow-x-hidden"}
      >
        {["createTicket", "recentTicket"]?.includes(active) ? (
          renderCreateTicket()
        ) : active === "rxc1" ? (
          <div>
            {isProduction && isPredict && (
              <div
                className={`absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 flex justify-center mt-[-40px] w-[600px] ${
                  showCamera ? "opacity-100" : "opacity-0"
                }`}
              >
                <Layout
                  removeHeight={true}
                  backgroundColor={"#fff"}
                  hideFooter={true}
                  hideLogo={true}
                  width={"100%"}
                >
                  <div className="bg-backgroundLightGray w-[38%] mt-[20px] py-[5px] px-[15px] text-[12px] rounded-[20px] flex items-center m-auto">
                    <img src={lock} alt="lock" className="mr-[5px]" />
                    {"Biometrics never leave this device"}
                  </div>
                  <div className="mt-[50px] rounded-[20px] flex flex-col items-center justify-center">
                    <CameraComponent
                      faceCamera={true}
                      onCameraReady={onCameraReady}
                      progress={loginPUID && loginGUID ? 100 : 0}
                      message={faceLoginWithLivenessMessage}
                      onSuccess={onSuccess}
                      attempt={
                        typeof faceLoginResponseStatus === "number" ? 1 : 0
                      }
                      scanCompleted={completed}
                      onCameraSwitch={() => faceLoginWithLiveness()}
                    />
                  </div>
                </Layout>
              </div>
            )}
            <div className="w-[75%] m-auto relative">
              <img
                src={isProduction ? Qp : RcHome}
                useMap="#image-map"
                alt="Main Menu"
                className="w-full"
              />
              {!isProduction && (
                <div
                  onClick={handleClick}
                  className="w-[480px] h-[150px] bg-transparent absolute z-[9999999] left-[47%] top-[25px] cursor-pointer"
                ></div>
              )}
            </div>
          </div>
        ) : activeTab === "pnp" ? (
          <div className="relative">
            <div className="w-[72%] m-auto relative">
              <div
                className="absolute top-[0px] right-[0px] w-[60px] h-[60px] cursor-pointer"
                onClick={() => {
                  setActiveTab("myShift");
                  setActive("home");
                }}
              />
              <img src={pnp} alt="pnp" className="w-full" />
            </div>
          </div>
        ) : activeTab === "myReports" ? (
          <div className="relative mb-[60px]">
            <div className="w-[79%] m-auto relative">
              <div
                className="absolute top-[0px] right-[0px] w-[90px] h-[60px] cursor-pointer"
                onClick={() => {
                  setActiveTab("myShift");
                  setActive("home");
                }}
              />
              <img
                src={myreport}
                alt="pnp"
                className="w-full"
              />
            </div>
          </div>
        ) : (
          <Layout
            hideLogo={true}
            removeHeight={false}
            logo={storeOs}
            removeBorder={true}
            heightOverride={true}
            shadow={false}
            hideFooter={true}
          >
            <div id="experiment">
              <div id="cube" className="cube">
                <div id="side">{_renderScreen()}</div>
              </div>
            </div>
          </Layout>
        )}

        <div className="fixed h-[60px] bottom-0 left-0 w-full bg-[#fff] footerShadow py-[12px] px-[12px] flex items-center">
          <div className="flex items-center">
            <img
              src={menu}
              alt="menu"
              className="mr-[18px] cursor-pointer max-md:w-[20px] max-[360px]:w-[15px]  max-[360px]:mr-[10px]"
            />
            <img
              src={search}
              alt="search"
              className="cursor-pointer max-md:w-[20px]  max-[360px]:w-[15px]"
            />
          </div>
          <div className="flex items-center flex-1 justify-center">
            <img
              src={home}
              alt="menu"
              className={`mx-[12px] max-md:mx-[6px] max-md:w-[20px] cursor-pointer border rounded-[10px] p-[10px] max-md:p-[2px]  max-[360px]:mx-[3px] ${
                ["home"].includes(active) && [""].includes(activeTab)
                  ? "border-[#7F7F7F]"
                  : "border-transparent"
              }`}
              onClick={() => {
                setActive("home");
                setActiveTab("");
                setIsProduction(false);
              }}
            />
            <img
              src={rxc1}
              alt="menu"
              className={`mx-[12px] max-md:mx-[6px] max-md:w-[20px] cursor-pointer border rounded-[10px] p-[10px] max-md:p-[2px]  max-[360px]:mx-[3px]  ${
                active === "rxc1" ? "border-[#7F7F7F]" : "border-transparent"
              }`}
              onClick={() => {
                setActive("rxc1");
                setIsProduction(false);
              }}
            />
            <img
              src={ticket}
              alt="menu"
              className={`mx-[12px] max-md:mx-[6px] max-md:w-[20px] cursor-pointer border rounded-[10px] p-[10px] max-md:p-[2px]  max-[360px]:mx-[3px] ${
                  active === "createTicket" ? "border-[#7F7F7F]" : "border-transparent"
              }`}
              onClick={() => {
                setActive("createTicket");
                setActiveTab("createTicket");
                setIsProduction(false);
              }}
            />
            <img
              src={inventry}
              alt="menu"
              className="mx-[12px] max-md:mx-[6px] cursor-pointer max-md:w-[20px] max-[360px]:mx-[3px]"
            />
            <img
              src={health}
              alt="menu"
              onClick={() => {
                setActive("pnp");
                setActiveTab("pnp");
                setIsProduction(false);
              }}
              className={`mx-[12px] max-md:mx-[6px] max-md:w-[20px] cursor-pointer border rounded-[10px] p-[10px] max-md:p-[2px]  max-[360px]:mx-[3px] ${
                active === "pnp" ? "border-[#7F7F7F]" : "border-transparent"
              }`}
            />
            <img
              src={clockstore}
              alt="menu"
              className="mx-[12px] max-md:mx-[6px] cursor-pointer max-md:w-[20px] max-[360px]:mx-[3px]"
            />
            <img
              src={reports}
              alt="menu"
              onClick={() => {
                setActive("myReports");
                setActiveTab("myReports");
              }}
              className={`mx-[12px] max-md:mx-[6px] max-md:w-[20px] cursor-pointer border rounded-[10px] p-[10px] max-md:p-[2px]  max-[360px]:mx-[3px] ${
                active === "myReports"
                  ? "border-[#7F7F7F]"
                  : "border-transparent"
              }`}
            />
            <img
              src={rx2}
              alt="menu"
              className="mx-[12px] max-md:mx-[6px] cursor-pointer max-md:w-[20px] max-[360px]:mx-[3px]"
            />
            <img
              src={allApp}
              alt="menu"
              className="mx-[12px] max-md:mx-[6px] cursor-pointer max-md:w-[20px] max-[360px]:mx-[3px]"
            />
          </div>
          <div className="flex items-center">
            <div className="mr-[16px]">
              <p className="text-[12px] font-[400] text-right text-[#262626] max-md:hidden">
                {currentTime}
              </p>
              <p className="text-[12px] font-[400] text-right text-[#262626] max-md:hidden">
                {currentDate}
              </p>
            </div>
            <img
              src={notification}
              alt="notification"
              className="cursor-pointer max-md:w-[20px]"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default StoreOs;

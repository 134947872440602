import { useState, useEffect, useContext } from "react";
import { loadPrivIdModule } from "@privateid/cryptonets-web-sdk";
import { getUrlParameter } from "../utils";
import { UserContext } from "context/userContext";

let isLoading = false;
const useWasm = () => {
  // Initialize the state
  const [ready, setReady] = useState(false);
  const [wasmStatus, setWasmStatus] = useState<any>({ isChecking: true });
  const context: any = useContext(UserContext);
  const { isWasmLoaded, setIsWasmLoaded } = context;
  const init = async () => {
    const default_api_key =
      process.env.REACT_APP_API_KEY || context?.configuration?.apiKey;
    const default_url =
      process.env.REACT_APP_API_URL || context?.configuration?.cryptonetsUrl;
    const apiKey = getUrlParameter("api_key", default_api_key) || "";
    const apiUrl = getUrlParameter("api_url", default_url) || "";
    const isSupported = await loadPrivIdModule({
      api_url: {
        collections: {
          default: {
            named_urls: {
              base_url: apiUrl,
              enroll: `${process.env.REACT_APP_API_URL}/FACE4_2/enroll`,
              predict: `${process.env.REACT_APP_API_URL}/FACE4_2/predict`,
              deleteUser: `${process.env.REACT_APP_API_URL}/FACE4_2/deleteUser`,
              syncUUID: `${process.env.REACT_APP_API_URL}/FACE4_2/syncUUID`,
            } as any,
          },
        },
      },
      api_key: apiKey,
    });
    if (isSupported.support) {
      setReady(true);
      setWasmStatus({ isChecking: false, ...isSupported });
      setIsWasmLoaded(true);
    } else {
      setReady(false);
      setWasmStatus({ isChecking: false, ...isSupported });
    }
  };

  useEffect(() => {
    if (ready) return;

    if (!isWasmLoaded && !isLoading) {
      init();
      isLoading = true;
    }
    if (isWasmLoaded) {
      setReady(true);
      setWasmStatus({ isChecking: false, support: true });
    }
  }, [isWasmLoaded]);

  return { ready, wasmStatus };
};

export default useWasm;

import { useContext, useState } from "react";
import lock from "assets/lock.svg";
import useCameraPermissions from "hooks/useCameraPermissions";
import { useNavigateWithQueryParams } from "utils/navigateWithQueryParams";
import { UserContext } from "context/userContext";
import { closeCamera } from "@privateid/cryptonets-web-sdk";
import Layout from "common/layout";
import BackButton from "common/components/backButton";
import CameraComponent from "common/components/camera";
import { ELEMENT_ID } from "constant";
import { useNavigation } from "utils/onNavigate";
import useContinuousPredict from "hooks/useContinuousAuthentication";

let invalidCount = 0;
let notEnrolled = 0;

function ContinuousAuthentication() {
  const [user, setUser] = useState<any>({});
  const context = useContext(UserContext);
  const pageConfig: any = context?.pageConfiguration;
  const { navigateWithQueryParams } = useNavigateWithQueryParams();
  const { navigateToNextPage } = useNavigation();
  const { isCameraGranted } = useCameraPermissions(() => {});
  const [scanCompleted, setScanCompleted] = useState(false);
  const onSuccess = () => {
    setTimeout(async () => {
      await closeCamera(ELEMENT_ID);
      if (pageConfig) {
        navigateToNextPage();
      } else {
        navigateWithQueryParams("/doc-selection");
      }
    }, 2000);
  };
  const handlePredictSuccess = async (result: any) => {
    // console.log(result, "result");
    if (result?.face_validation_status === 0) {
      invalidCount = 0;
      notEnrolled = 0;
      setUser(result);
      setProgress(100);
      localStorage.setItem("uuid", JSON.stringify(result?.puid || {}));
    } else if (result?.face_validation_status === -1) {
      localStorage.removeItem("uuid");

      notEnrolled++;
      setUser(result);
      setProgress(100);
      if (notEnrolled >= 5) {
        notEnrolled = 0;
      }
    } else {
      localStorage.removeItem("uuid");
      setUser({});
      invalidCount++;
    }
  };

  const continuousOnNotFoundAndFailure = () => {
    localStorage.removeItem("uuid");
    setUser({});
    setProgress(0);
    setUser(null);
  };

  const continuousOnNotFound = () => {
    setProgress(0);
    setUser(null);
  }

  const {
    predictUser: continuousPredictUser,
    continuousPredictMessage,
    setProgress,
    progress,
  } = useContinuousPredict(
    undefined,
    handlePredictSuccess,
    continuousOnNotFoundAndFailure,
    continuousOnNotFound
  );

  const onCameraReady = () => {
    continuousPredictUser();
  };

  return (
    <div
      style={{ backgroundColor: user?.guid && "green" }}
      className={"h-[100vh] max-md:h-unset"}
    >
      <Layout removeHeight={!isCameraGranted} backgroundColor={"#fff"}>
        <div className="px-10 py-8 max-md:p-[20px] max-md:pt-[20px]">
          <div className="flex justify-between relative">
            <BackButton isCamera />
            <div className="bg-backgroundLightGray py-[5px] px-[15px] text-[12px] rounded-[20px] flex items-center m-auto">
              <img src={lock} alt="lock" className="mr-[5px]" />
              {pageConfig?.title || "Biometrics never leave this device"}
            </div>
          </div>
          <div className="mt-[50px] rounded-[20px] flex flex-col items-center justify-center">
            <CameraComponent
              faceCamera={true}
              onCameraReady={onCameraReady}
              progress={progress}
              message={continuousPredictMessage}
              onSuccess={onSuccess}
              attempt={continuousPredictMessage ? 1 : 0}
              scanCompleted={scanCompleted}
            />
          </div>
        </div>
      </Layout>
    </div>
  );
}

export default ContinuousAuthentication;

import { useState } from "react";
import { predictAge } from "@privateid/cryptonets-web-sdk";
import { getStatusMessage } from "@privateid/cryptonets-web-sdk/dist/utils";

let skipAntispoofGlobal: any = false;
const useMultiFramePredictAge = (onSuccess: {
  (resultAge: any, portrait: any): void;
  (arg0: any, arg1: any): void;
}) => {
  const [age, setAge] = useState<any>(null);
  const [antispoofPerformed, setAntispoofPerformed] = useState<any>(false);
  const [antispoofStatus, setAntispoofStatus] = useState<string>("");
  const [validationStatus, setValidationStatus] = useState<string>("");
  const [statusMessage, setStatusMessage] = useState<any>("");
  const [progress, setProgress] = useState(0);

  const mfCallback = (result: any) => {
    // console.log(result);

    if(result?.age && result?.age > 0){
      setAntispoofStatus(result?.antispoof_status);
      setAntispoofPerformed(result?.anti_spoof_performed)
      setValidationStatus(result?.face_validation_status);
      setStatusMessage(getStatusMessage(result?.face_validation_status));
      setAge(result?.age);
      onSuccess(result?.age, result?.portrait)
    }
    else{
      setAge("");
      setAntispoofStatus(result?.antispoof_status);
      setValidationStatus(result?.face_validation_status);
      doPredictAge(skipAntispoofGlobal, result?.mf_token);
      setStatusMessage(getStatusMessage(result?.face_validation_status));
    }
  };

  const doPredictAge = async (skipAntispoof = false, mfToken = "") => {
    if (mfToken) {
      setProgress((p) => p + 20);
    } else {
      setProgress(0);
    }
    skipAntispoofGlobal = skipAntispoof;
    await predictAge({
      callback: mfCallback,
      config: {
        // skip_antispoof: true,
        mf_token: mfToken,
      },
    });
  };

  return {
    doPredictAge,
    age,
    antispoofPerformed,
    antispoofStatus,
    validationStatus,
    statusMessage,
    progress,
    setProgress
  };
};

export default useMultiFramePredictAge;

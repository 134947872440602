import { useContext, useEffect } from "react";
import Lottie from "lottie-react";
import { UserContext } from "context/userContext";
import successJson from "Animations/5-Verify/JSONs/Success.json";
import { Label } from "components/ui/label";
import { Button } from "components/ui/button";
import { extractRoute, isMobile } from "utils";
import BackButton from "common/components/backButton";
import CloseButton from "common/components/closeButton";
import { useNavigation } from "utils/onNavigate";
import { useNavigate } from "react-router-dom";
import { useNavigateWithQueryParams } from "utils/navigateWithQueryParams";
import StoreOsLayout from "../layout";

type Props = {
  heading?: string;
};

function StoreOsSuccess(Props: Props) {
  const context: any = useContext(UserContext);
  const pageConfig: any = context?.pageConfiguration;
  const { navigateWithQueryParams } = useNavigateWithQueryParams();
  const { navigateToNextPage } = useNavigation();
  const navigate = useNavigate();
  const uuid = localStorage.getItem("uuid");
  // console.log({context})
  const loadPageConfiguration = () => {
    const currentRouteIndex = context?.configuration?.appPages?.findIndex(
      (item: any) => item?.currentRoute === "/"
    );
    context?.setPageIndex(currentRouteIndex);
    const pageConfiguration = extractRoute(
      context?.configuration,
      currentRouteIndex
    );
    context?.setLoginOption("");
    context?.setPageConfiguration(pageConfiguration);
  };
  const onSignout = () => {
    if (context?.configuration?.isKantara && pageConfig?.type !== "completed") {
      navigateToNextPage();
      return;
    }
    localStorage.removeItem("uuid");
    localStorage.removeItem("user");
    loadPageConfiguration();
    if (pageConfig) {
      navigate("/");
    } else {
      navigateWithQueryParams("/", { token: undefined });
    }
  };
  const onSecondaryButton = () => {
    localStorage.removeItem("uuid");
    loadPageConfiguration();
    if (pageConfig) {
      navigateToNextPage("", "/", "/success");
    } else {
      navigateWithQueryParams("/", { token: undefined });
    }
  };
  useEffect(() => {
    context.setLoginOption("");
    setTimeout(() => {
      navigateToNextPage("", "/", "/success");
    }, 3000)
  }, []);

  const backToHome = () => {
    context?.setIsGenerateUuid(false);
    loadPageConfiguration();
    navigateWithQueryParams("/", { token: undefined });
  };
  return (
    <StoreOsLayout isCard>
      <div className="h-full px-10 py-8 flex justify-between items-center flex-col max-md:p-[20px]">
        <div className="w-full">
          <div className="flex justify-between relative">
            {/* <BackButton />
            <CloseButton /> */}
          </div>
          <div className="flex justify-center items-center mb-[-3rem]">
            <Lottie
              loop={false}
              autoplay={true}
              animationData={successJson}
              style={{
                height: isMobile ? 350 : 400,
                width: isMobile ? 380 : 400,
              }}
              className="max-[360px]:!h-[300px]"
            />
          </div>
          {pageConfig?.type === "ageSuccess" ? (
            <Label className="text-[28px] font-[500] text-primaryText w-[90%] mt-[-7rem] max-md:mt-[-4rem]">
              {pageConfig?.message} {context.age}
            </Label>
          ) : (
            <>
              <Label className="text-[28px] font-[500] text-primaryText w-[90%] mt-[-7rem] max-md:mt-[-4rem] max-[360px]:text-[24px]">
                {pageConfig?.message ||
                  context?.successMessage ||
                  Props.heading ||
                  "Success! Your account is created"}
              </Label>
              <br />
              {/* <Label className="text-[14px] font-[400] text-secondaryText">
                uuid: {uuid?.slice(1, -1)}
              </Label> */}
            </>
          )}
          {pageConfig?.type === "completed" && (
            <p className="text-[14px] font-[400]">
              A text message was sent to your phone to confirm your Private ID
              account. No personal details, images or biometric data are
              retained. This account will expire after two years of inactivity.
            </p>
          )}
        </div>
        <div
          className={`text-left w-full left-0 bottom-0 rounded-b-[24px] ps-10 pe-10 pt-6 pb-0 max-md:h-[unset] max-md:relative mt-[7rem]`}
        >
          <div>
            <Button
              className="w-full text-white bg-primary rounded-[24px] mt-4 hover:opacity-90 hover:bg-primary hover:text-white"
              onClick={() => onSignout()}
            >
              {pageConfig?.buttonText || "Sign out"}
            </Button>
            {pageConfig?.secondaryButton && (
              <Button
                className="w-full text-white bg-primary rounded-[24px] mt-4 hover:opacity-90 hover:bg-primary hover:text-white"
                onClick={() =>
                  context?.configuration?.isKantara
                    ? onSecondaryButton()
                    : pageConfig
                    ? backToHome()
                    : navigateWithQueryParams("/login-options", {
                        token: undefined,
                      })
                }
              >
                {pageConfig?.secondaryButton || "Return to options"}
              </Button>
            )}
          </div>
        </div>
      </div>
    </StoreOsLayout>
  );
}

export default StoreOsSuccess;

import { Eye, EyeOff } from "lucide-react";
import { useState } from "react";
import { UseFormRegister } from "react-hook-form";
import AlertIcon from "assets/alert.svg";

type Props = {
  label: string;
  type?: string;
  register?: UseFormRegister<any> | any;
  name?: string;
  error?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

function StoreOsInput({
  label,
  type,
  register,
  name,
  error,
  onChange,
  ...props
}: Props | any) {
  const [eye, setEye] = useState(false);
  return (
    <div className="w-full text-left mb-[24px] relative">
      <p className="text-[15px] font-[500] text-[#262626] mb-[8px]">
        {label}
        <span className="text-[red] ml-[5px]">*</span>
      </p>
      <input
        type={eye ? "text" : type || "text"}
        {...register}
        className={`border border-1 focus:border-2 p-[10px] !outline-none border-[#262626] h-[44px] rounded-[10px] w-full ${
          error ? "border-red-500" : ""
        }`}
        {...props}
      />
      {/* Eye icon for password */}
      {type === "password" && (
        <div
          onClick={() => setEye(!eye)}
          className="absolute right-[10px] top-[40px] cursor-pointer"
        >
          {eye ? <Eye className="w-[20px]" /> : <EyeOff className="w-[20px]" />}
        </div>
      )}
      {/* Display error state */}
      {error && (
        <div className="flex items-center mt-[7px] mb-[-10px]">
          <img
            src={AlertIcon}
            alt="AlertIcon"
            className="mr-[8px] h-[16px] w-[16px]"
          />
          <p className="text-[#DB3321] text-[14px] mt-[2px] font-[400]">
            {error}
          </p>
        </div>
      )}{" "}
    </div>
  );
}

export default StoreOsInput;

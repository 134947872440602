import StoreOsLayout from "../layout";
import storeUser from "assets/storeUser.svg";
import StoreOsButton from "common/components/storeOsButton";
import clockIcon from "assets/clock.svg";
import profile from "assets/profile.svg";
import { useNavigation } from "utils/onNavigate";
import { Lock } from "lucide-react";
import StoreOsInput from "common/components/storeOsInput";
import FaceLoginStoreOs from "common/storeOS/facialScanLogin";
import { useState } from "react";
import { isMobile } from "utils";
import StoreOsOtpInput from "common/components/storeOsOtpInput";
import { Label } from "components/ui/label";

function LockScreen() {
  const { navigateToNextPage } = useNavigation();
  const [showCamera, setShowCamera] = useState(false);
  const [showInput, setShowinput] = useState(false);

  const onUnlock = () => {
    if (isMobile) {
      navigateToNextPage("", "/store-os/facial-scan-login", "/store-os/landing");
      return;
    }
    if (!showCamera && !showInput) {
      setShowCamera(true);
      setTimeout(() => {
        setShowCamera(false);
        setShowinput(true);
      }, 4000);
    } else if (!showInput) {
      setShowinput(true);
    } else {
      navigateToNextPage("", "/store-os", "/store-os/landing");
    }
  };
  const onSwitchDevice = () => {
    localStorage.setItem("lockedBarcode", "true")
    navigateToNextPage("", "/store-os/bar-code", "/store-os/facial-scan");
  };
  return (
    <StoreOsLayout className='max-[360px]:p-[10px]'>
      {showCamera && (
        <div className="opacity-0 fixed">
          {" "}
          <FaceLoginStoreOs isLockScreen={true} />
        </div>
      )}
      <div className="h-[60%] flex items-center justify-center flex-col max-md:h-[58%] max-[360px]:p-[10px]">
        <div className="flex justify-center items-center w-full max-w-[720px] py-[35px] px-[60px] m-auto max-[360px]:max-w-[unset] max-[360px]:p-[0px]">
          <div className="border border-border rounded-[24px] bg-white text-center innerCardStore p-[30px]  max-[360px]:p-[20px]">
            <div className="mb-[10px] flex items-center">
              <img
                src={profile}
                alt="profile"
                className="w-[50px] h-[50px] mr-[10px]"
              />
              <p className="font-[500] text-[#0B315F] text-[35px]">
                Karen S Lynch
              </p>
            </div>
            <div className="w-full h-[3px] bg-[#FAF9FA] mt-[10px] mb-[10px]" />
            {showInput && (
              <StoreOsInput
                label="Scan or Enter Password/RxC Credentials"
                type="password"
              />
            )}

            {isMobile && (
              <div className="mt-[0px] mb-[20px]">
                <StoreOsOtpInput
                  hideLabel
                  onClick={() =>
                    navigateToNextPage(
                      "",
                      "/store-os/facial-scan-login",
                      "/store-os/landing"
                    )
                  }
                />
              </div>
            )}

            <StoreOsButton
              title={
                isMobile ? (
                  "Confirm"
                ) : (
                  <>
                    <Lock className="mr-[8px] w-[23px] h-[23px]" />
                    {!showCamera
                      ? "Unlock with face Scan"
                      : !showInput
                      ? "Unlock with password"
                      : "Unlock"}
                  </>
                )
              }
              onClick={() => onUnlock()}
            />
            <div className="mt-[30px] mb-[0px]">
              <div className="text-primary text-[14px] cursor-pointer block text-center mb-0 mt-[0px] max-lg:hidden">
                <Label className="font-[400] text-secondaryText text-[14px]">
                  {"Having problems?"}
                </Label>
                <Label
                  className="font-[500] text-primary ml-[6px] cursor-pointer text-[14px] hover:underline"
                  onClick={async () => onSwitchDevice()}
                >
                  Switch to mobile
                </Label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="max-w-[800px] m-auto max-[360px]:max-w-[unset]">
        <div className="mb-[50px] flex justify-center items-center flex-col max-md:mb-[20px]">
          <button
            onClick={() =>
              navigateToNextPage(
                "",
                "/store-os/facial-scan",
                "/store-os/landing"
              )
            }
            className="border border-[#262626] px-[26px] py-[10px] text-[16px] text-[#262626] font-[500] rounded-[60px] flex items-center justify-center"
          >
            <img
              src={storeUser}
              alt="shieldSetting"
              className="mr-[8px] userIcon"
            />
            Sign in as a different user
          </button>
          <button
            onClick={() =>
              navigateToNextPage(
                "",
                "/store-os/facial-scan-login",
                "/store-os/lock-screen"
              )
            }
            className="border border-[#262626] px-[26px] py-[10px] text-[16px] text-[#262626] font-[500] rounded-[60px] flex items-center justify-center max-md:mt-[20px] mt-[32px]"
          >
            <img src={clockIcon} alt="shieldSetting" className="mr-[8px]" />
            Time In/Out
          </button>
        </div>
        <p className="text-center text-[#575757] text-[15px] font-[400] max-md:px-[10px]  max-[360px]:text-[13px]  max-[360px]:pb-[20px]">
          Note: You are responsible for all activities performed at a
          workstation while logged in. Use the F10 key to log out when stepping
          away from your workstation to prevent unauthorized use of the
          workstation by another colleague.
        </p>
      </div>
    </StoreOsLayout>
  );
}

export default LockScreen;

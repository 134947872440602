function StoreOsToastSmall({
  title,
  description,
  isError,
  visible,
}: {
  title: string;
  description: string | any;
  isError?: boolean;
  visible?: boolean;
}) {
  return (
    <div
      className={`${
        isError ? "bg-[#DB3321]" : "bg-[#118738]"
      }  py-[20px] px-[20px] mt-[60px] max-md:mt-[35px] min-w-[380px] left-0 rounded-[8px] transition-all delay-100 ${visible ? 'opacity-100' : 'opacity-0'} max-md:p-[10px]`}
    >
      <h4 className={`text-left text-white text-[15px] font-[500]`}>{title}</h4>
      <p className={`text-left text-white text-[14px] font-[400]`}>
        {description}
      </p>
    </div>
  );
}

export default StoreOsToastSmall;

import StoreOsLayout from "../layout";
import storeOsLogo from "assets/storeOSLogo.png";
import storeUser from "assets/storeUser.svg";
import StoreOsButton from "common/components/storeOsButton";
import clockIcon from "assets/clockIcon.svg";
import storeEdit from "assets/pencil.svg";
import { useNavigation } from "utils/onNavigate";
import StoreOsToastSmall from "common/components/storeOsToastSmall";
import { useEffect, useState } from "react";
import { isMobile } from "utils";
import { useSearchParams } from "react-router-dom";

function Landing() {
  const { navigateToNextPage } = useNavigation();
  const [isState, setIsState] = useState(false);
  const [isError, setIsError] = useState(false);
  const uuid: string = localStorage.getItem("uuid") || "";
  const isLocked: boolean = localStorage.getItem("locked") === "true";
  const isLoggedIn = uuid?.length > 0;
  const [burningManValue, setBurningManValue] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const burningMan = searchParams.get("burningMan") === "true";
  const handleChange = () => {
    const burningMan = searchParams.get("burningMan") === "true";
    setSearchParams({
      burningMan: (!burningMan).toString(),
    });
    setBurningManValue(!burningManValue);
  };

  useEffect(() => {
    if (isLoggedIn) {
      setIsState(true);
      setTimeout(() => {
        setIsState(false);
        localStorage.removeItem("uuid");
      }, 2000);
    } else if (isLocked) {
      localStorage.removeItem("locked");
    }
  }, [isLoggedIn, isLocked]);

  const onSignin = () => {
    if (isMobile) {
      navigateToNextPage("", "/store-os/signin", "/store-os/landing");
    } else {
      navigateToNextPage(
        "",
        "/store-os/facial-scan-login",
        "/store-os/landing"
      );
    }
  };
  return (
    <StoreOsLayout landingScreen>
      <div className="h-[65%] max-[360px]:h-[unset] max-[360px]:mb-[30px] flex items-center justify-center flex-col max-[767px]:h-[45%] max-[767px]:mb-[40px] max-[767px]:mt-[30px]">
        <img
          src={storeOsLogo}
          alt="storeOsLogo"
          className="mb-[60px] w-[200px] max-[767px]:mt-[30px] max-[767px]:mb-[30px]"
        />
        <StoreOsButton
          title={
            <>
              <img
                src={storeUser}
                alt="storeUser"
                className="mr-[8px] w-[23px] h-[23px]"
              />
              Sign In
            </>
          }
          onClick={() => onSignin()}
          className="max-[360px]:min-w-[300px]"
        />
        <div
          className={`${
            isState && isMobile ? "block" : isMobile ? "hidden" : "block"
          }`}
        >
          <StoreOsToastSmall
            title={isError ? "Unable to sign in" : "Success!"}
            description={
              isError
                ? "Please try again later. We appreciate your patience."
                : "You have registered for Facial Scan."
            }
            visible={isState}
            isError={isError}
          />
        </div>
        {/* <StoreOsToastSmall
          title={"Account Locked"}
          description={
            <>
              Your account has been locked due to too many incorrect sign in
              attempts.
              <br /> Please call the Help Desk for assistance.
            </>
          }
          visible={isLocked}
          isError={true}
        /> */}
      </div>
      <div className="max-w-[800px] m-auto max-[767px]:p-[10px]">
        <div className="mb-[30px] flex justify-center flex-col items-center max-[767px]:flex-col max-[767px]:mb-[20px]">
          <button
            // onClick={() =>
            //   navigateToNextPage(
            //     "",
            //     "/store-os/facial-scan",
            //     "/store-os/landing"
            //   )
            // }
            className="border w-[240px] border-[#CFCECE] bg-[#FFFFFF] max-[767px]:mb-[20px] px-[26px] py-[10px] text-[16px] text-[#575757] font-[500] rounded-[60px] flex items-center justify-center mb-[15px] max-[767px]:mr-0"
          >
            <img src={clockIcon} alt="shieldSetting" className="mr-[8px]" />
            Time In/Out
          </button>
          <button
            onClick={() =>
              navigateToNextPage("", "/store-os/signup", "/store-os/landing")
            }
            className="border w-[240px] border-[#CFCECE] bg-[#FFFFFF] px-[16px] py-[10px] text-[16px] text-[#575757] font-[500] rounded-[60px] flex items-center justify-center"
          >
            <img src={storeEdit} alt="shieldSetting" className="mr-[8px]" />
            Register for Facial Scan
          </button>
        </div>
        <p className="text-center text-[#575757] text-[15px] font-[400] max-[360px]:text-[13px]">
          Note: You are responsible for all activities performed at a
          workstation while logged in. Use the F10 key to log out when stepping
          away from your workstation to prevent unauthorized use of the
          workstation by another colleague.
        </p>
      </div>
      <div className="bottom-[10px] relative">
        <label className="flex items-center justify-end cursor-pointer mr-2">
          <span className="mr-3 text-sm font-medium text-gray-900 dark:text-gray-300">
            Camera Test
          </span>
          <input
            type="checkbox"
            checked={burningManValue}
            onChange={handleChange}
            className="sr-only peer"
          />
          <div
            className={`relative w-11 h-6 bg-gray-200 peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full ${
              burningMan ? "!bg-primary" : "bg-gray-400"
            }`}
          >
            <div
              className={`absolute w-5 h-5 bg-white rounded-full transition-transform peer-checked:transform peer-checked:translate-x-full rtl:peer-checked:-translate-x-full ${
                burningMan ? "translate-x-full" : ""
              } top-[2.2px] left-[2px]`}
            ></div>
          </div>
        </label>
      </div>
    </StoreOsLayout>
  );
}

export default Landing;

import minus from "assets/minus.svg";
import close from "assets/closeX.svg";
import searchImage from "assets/searchImage.svg";
import { ticketData } from "constant";
import ConfirmModal from "./confirmModal";
import { useState } from "react";
import recentTicket from "assets/recentTickets.jpg";

function CreateTicket({ setActiveTab, setActive, active }: any) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [title, setTitle] = useState("");
  return (
    <div>
      <div className="h-[59px] bg-[#767676] w-[100%] flex items-center justify-center relative">
        <p className="text-center text-[#FFFFFF] text-[22px]">
          {active === "recentTicket" ? "Recent Tickets" : "Create Ticket"}
        </p>
        <div className="flex items-center absolute right-5">
          <img
            src={minus}
            alt="minus"
            className="mr-[30px] w-[17px] cursor-pointer"
          />
          <img
            src={close}
            alt="close"
            className="w-[17px] cursor-pointer"
            onClick={() => {
              setActiveTab("myStore");
              setActive("home");
            }}
          />
        </div>
      </div>
      {active === "recentTicket" ? (
        <>
          <img
            src={recentTicket}
            alt="recentTicket"
            className="w-[80%] m-auto mb-[80px]"
          />
        </>
      ) : (
        <div className="py-8 max-w-[800px] w-[100%] m-auto">
          <img src={searchImage} alt="searchImage" className="w-[60%]" />
          <div className="flex items-center justify-between mt-[30px] flex-wrap  max-md:block mb-[20px]">
            {ticketData?.map((item) => {
              return (
                <div
                  onClick={() => {
                    setIsModalOpen(!isModalOpen);
                    setTitle(item?.label);
                  }}
                  className={`${
                    item?.disable
                      ? "bg-[#CCCCCC] !border-dashed"
                      : "bg-[#fff] !border-solid cursor-pointer"
                  } p-[13px] rounded-[20px] shadow-lg h-[170px] mb-[20px] max-md:h-[100px] w-[23%] smallCardOs  max-md:w-[100%]  max-md:mb-[30px] flex flex-col items-center justify-center`}
                >
                  <img
                    src={item?.image}
                    className="w-[120px] max-md:w-[190px] max-md:h-[40px] m-auto mb-[10px] h-[50px] object-contain mt-[0px]"
                    alt="user"
                  />
                  <p className="text-[#262626] text-[16px] font-[500] text-center max-md:text-[16px] leading-6">
                    {item?.label}
                  </p>
                  {item?.subHeading && (
                    <p className="text-center text-[#E07800] leading-6">
                      {item?.subHeading}
                    </p>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}

      {isModalOpen && (
        <ConfirmModal
          setIsModalOpen={setIsModalOpen}
          isModalOpen={isModalOpen}
          title={title}
          setActiveTab={setActiveTab}
          setActive={setActive}
        />
      )}
    </div>
  );
}

export default CreateTicket;

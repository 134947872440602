import { Eye, EyeOff } from "lucide-react";
import { useRef, useState } from "react";
import PinInput from "react-pin-input";
import faceIcon from "assets/faceIcon.svg";

function StoreOsOtpInput(props: any) {
  const { label, hideLabel, onClick } = props;
  const [eye, setEye] = useState(false);
  const pinInputRef = useRef<any>(null);

  const toggleEye = () => {
    setEye(!eye);
    if (pinInputRef.current) {
      pinInputRef.current?.elements?.map((item: any) => {
        const type = item.input.type === "password" ? "text" : "password";
        return (item.input.type = type);
      });
    }
  };
  return (
    <>
      {!hideLabel && (
        <p className="text-[15px] font-[500] text-[#262626] mb-[8px]">
          {label}
          <span className="text-[red] ml-[5px]">*</span>
        </p>
      )}

      <div className="flex items-center">
        <PinInput
          ref={pinInputRef}
          length={4}
          initialValue=""
          secret={!eye} // Toggle the secret property based on the eye state
          focus
          secretDelay={100}
          type="numeric"
          inputMode="number"
          style={{ paddingTop: "10px", marginLeft: -7 }}
          ariaLabel={`pin`}
          inputStyle={{
            borderColor: "gray",
            width: false ? "30px" : "40px",
            height: false ? "30px" : "60px",
            fontSize: false ? "20px" : "24px",
            marginLeft: 10,
            transition: "all .2s",
            borderRadius: 6,
          }}
          inputFocusStyle={{
            borderColor: "#5283EC",
            transform: "scale(1.1)",
          }}
          // onComplete={(value) => {
          //   setOtp(value);
          // }}
          autoSelect={true}
          regexCriteria={/^[0-9]*$/}
        />
        {hideLabel ? (
          <img
            src={faceIcon}
            alt="faceIcon"
            className="ml-[20px] cursor-pointer"
            onClick={onClick}
          />
        ) : (
          <div onClick={() => toggleEye()} className="cursor-pointer ml-[20px]">
            {eye ? (
              <Eye className="w-[20px]" />
            ) : (
              <EyeOff className="w-[20px]" />
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default StoreOsOtpInput;

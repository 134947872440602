import { SetStateAction, useEffect, useState } from "react";
import {
  backScanDocument,
  convertCroppedImage,
  // isValidPhotoID,
} from "@privateid/cryptonets-web-sdk";

const useScanBackDocument = (onSuccess: (e: any) => void) => {
  const [scannedCodeData, setScannedCodeData] = useState<any>({});
  const [isFound, setIsFound] = useState(false);

  // raw byte
  const [inputImageData, setInputImageData] = useState<any>(null);
  const [croppedDocumentRaw, setCroppedDocumentRaw] = useState<any>(null);
  const [croppedBarcodeRaw, setCroppedBarcodeRaw] = useState<any>(null);

  // image width
  const [croppedDocumentImage, setCroppedDocumentImage] = useState(null);
  const [croppedDocumentWidth, setCroppedDocumentWidth] = useState(null);
  const [croppedBarcodeWidth, setCroppedBarcodeWidth] = useState(null);

  // image height
  const [croppedDocumentHeight, setCroppedDocumentHeight] = useState(null);
  const [croppedBarcodeHeight, setCroppedBarcodeHeight] = useState(null);

  // base64 image
  const [inputImageBase64, setInputImageBase64] = useState(null);
  const [croppedDocumentBase64, setCroppedDocumentBase64] = useState(null);
  const [croppedBarcodeBase64, setCroppedBarcodeBase64] = useState(null);

  const [barcodeStatusCode, setBarcodeStatusCode] = useState<any>(null);
  const [returnValue, setResultValue] = useState<any>(null);

  const doConvert = async (message: any, imageData: any) => {
    try {
      if (imageData.data.length === imageData.width * imageData.height * 4) {
        const b64Converted = await convertCroppedImage(
          imageData.data,
          imageData.width,
          imageData.height
        );
        // console.log(`${message}`, b64Converted);
        return b64Converted;
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (isFound && croppedDocumentRaw && returnValue) {
      // console.log("cropped document start: ", {
      //   isFound,
      //   croppedDocumentRaw,
      //   returnValue,
      // });
      if (croppedDocumentRaw?.length && returnValue?.cropped_document?.width) {
        if (
          croppedDocumentRaw.length ===
          returnValue.cropped_document.width *
            returnValue.cropped_document.height *
            4
        ) {
          const image = new ImageData(
            croppedDocumentRaw,
            returnValue.cropped_document.width,
            returnValue.cropped_document.height
          );
          // console.log("cropped document image:", image);
          setCroppedDocumentRaw(image);
          if (image?.data?.length) {
            doConvert("cropped document image:", image).then((base64: any) => setCroppedBarcodeBase64(base64));
            
          }
        }
      }
    }
  }, [isFound, croppedDocumentRaw, returnValue]);

  useEffect(() => {
    if (isFound && croppedBarcodeRaw && returnValue) {
      // console.log("cropped barcode start: ", {
      //   isFound,
      //   croppedBarcodeRaw,
      //   returnValue,
      // });
      // console.log("data length:", croppedBarcodeRaw?.length);
      if (croppedBarcodeRaw?.length && returnValue?.cropped_barcode?.width) {
        // console.log("converting?");
        if (
          croppedBarcodeRaw.length ===
          returnValue.cropped_barcode.width *
            returnValue.cropped_barcode.height *
            4
        ) {
          const image = new ImageData(
            croppedBarcodeRaw,
            returnValue.cropped_barcode.width,
            returnValue.cropped_barcode.height
          );
          // console.log("cropped barcode image:", image);
          setCroppedDocumentRaw(image);
          if (image?.data?.length) {
            doConvert("cropped barcode image:", image).then((base64: any) => setCroppedBarcodeBase64(base64));
          }
        }
      }
    }
  }, [isFound, croppedBarcodeRaw, returnValue]);

  useEffect(() => {
    if (isFound && inputImageData) {
      if (isFound && inputImageData?.data?.length) {
        doConvert("input image:", inputImageData).then((base64: any) => setInputImageBase64(base64));
        
      }
    }
  }, [isFound, inputImageData]);

  const documentCallback = (result: any) => {
    // console.log("Back scan callback result:", result);
    if (result.barcode_detection_status === 0) {
      setBarcodeStatusCode(0);
      setIsFound(true);
      setResultValue(result);
      setScannedCodeData(result);
      setCroppedDocumentWidth(returnValue?.cropped_document?.width);
      setCroppedDocumentHeight(returnValue?.cropped_document?.height);
      setCroppedBarcodeWidth(returnValue?.cropped_barcode?.width);
      setCroppedBarcodeHeight(returnValue?.cropped_barcode?.height);
    } else {
      setBarcodeStatusCode(
        result.document_validation_status || result.barcode_detection_status
      );
      scanBackDocument();
    }
  };

  const convertImageToBase64 = async (
    imageData: any,
    width: any,
    height: any,
    setState: SetStateAction<any>
  ) => {
    if (imageData.length === width * height * 4) {
      const imageBase64 = await convertCroppedImage(imageData, width, height);
      setState(imageBase64);
    }
  };

  // // Converting Cropped Document
  useEffect(() => {
    if (
      isFound &&
      croppedDocumentRaw &&
      croppedDocumentWidth &&
      croppedDocumentHeight
    ) {
      convertImageToBase64(
        croppedDocumentRaw,
        croppedDocumentWidth,
        croppedDocumentHeight,
        setCroppedDocumentBase64
      );
    }
  }, [
    croppedDocumentRaw,
    croppedDocumentWidth,
    croppedDocumentHeight,
    isFound,
  ]);
  
  // onSuccess Callback
  useEffect(() => {
    if (
      (isFound &&
        // inputImageBase64 &&
        croppedBarcodeBase64 &&
        scannedCodeData) ||
      (isFound &&
        croppedBarcodeBase64 &&
        scannedCodeData.first_name &&
        !scannedCodeData.crop_doc_width)
    ) {
      onSuccess({
        inputImage: inputImageBase64,
        croppedDocument: croppedDocumentBase64,
        croppedBarcode: croppedBarcodeBase64,
        barcodeData: scannedCodeData,
      });
    }
  }, [
    isFound,
    inputImageBase64,
    croppedDocumentBase64,
    croppedBarcodeBase64,
    scannedCodeData,
    croppedBarcodeRaw,
  ]);

  const scanBackDocument = async (canvasSize?: any) => {
    // const canvasObj = canvasSize ? CANVAS_SIZE[canvasSize] : internalCanvasSize ? CANVAS_SIZE[internalCanvasSize] : {};
    const { croppedBarcode, croppedDocument, imageData }: any =
      await backScanDocument({
        callback: documentCallback,
        // image: uploadData,
        config: {
          document_scan_barcode_only: true,
          blur_threshold_barcode: 1000,
          blur_threshold_doc: 1000,
          conf_score_thr_doc: 0.3,
          threshold_doc_too_close: 99,
          threshold_doc_too_far: 0,
          fingers_over_document_threshold: 0.3,
          barcode_margin: 0.1,
        },
      });
    // console.log({ croppedBarcode, croppedDocument, imageData });
    setCroppedDocumentRaw(croppedDocument);
    setCroppedBarcodeRaw(croppedBarcode);
    setInputImageData(imageData);
  };

  return {
    scanBackDocument,
    scannedCodeData,
    isFound,
    croppedDocumentBase64,
    croppedBarcodeBase64,
    barcodeStatusCode,
    croppedDocumentImage,
  };
};

export default useScanBackDocument;

import { Label } from "../../components/ui/label";
import { Layout } from "../index";
import BackButton from "common/components/backButton";
import CloseButton from "common/components/closeButton";
import { UserContext } from "context/userContext";
import { useContext, useState } from "react";
import { Button } from "components/ui/button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";
import { Input } from "components/ui/input";
import { useNavigation } from "utils/onNavigate";
import { SECURITY_CHALLENGE } from "constant";

const Questions = [
  {
    label: "Who is your favorite artist?",
    value: "favorite-artist",
  },
  {
    label: "What is your least favorite subject in school?",
    value: "favorite-subject",
  },
  {
    label: "What is your favorite musical instrument?",
    value: "favorite-musical",
  },
];
const Challenge = () => {
  const context: any = useContext(UserContext);
  const pageConfig: any = context?.pageConfiguration;
  const [challenge, setChallenge] = useState("");
  const [answer, setAnswer] = useState("");
  const { navigateToNextPage } = useNavigation();
  const options = pageConfig?.challengeQuestions || Questions;
  return (
    <Layout>
      <div className="relative flex text-center justify-center">
        <div className="relative w-full">
          <div className="px-10 py-8 max-md:p-[20px]">
            <div className="flex justify-between relative">
              <BackButton />
              <div className="flex items-center justify-center w-full" />
              <div>{/* {Empty div to adjust space} */}</div>
              <CloseButton />
            </div>
            <div className="mt-2 mb-[20px]">
              <Label className="text-[28px] font-[500] text-primaryText">
                {pageConfig?.title || `Security Challenge.`}
              </Label>
            </div>
            <div className="text-center overflow-auto h-[420px] mt-3 p-2 max-[360px]:h-[100%]">
              <Select
                onValueChange={(e: string) => setChallenge(e)}
                value={
                  options?.find((questions: any) => questions?.value === challenge)
                    ?.value
                }
              >
                <SelectTrigger
                  className="h-[48px] mt-[0px] font-[400] rounded-[8px] border-borderSecondary {
                      ] text-[14px] focus:outline-none  focus:ring-transparent"
                >
                  <SelectValue placeholder="Select a challenge question" />
                </SelectTrigger>
                <SelectContent>
                  {options.map((item: { label: string; value: string }) => {
                    return (
                      <SelectItem value={item?.value} key={item?.value}>
                        {item?.label}
                      </SelectItem>
                    );
                  })}
                </SelectContent>
              </Select>
              {challenge && (
                <Input
                  placeholder={"Answer"}
                  className="h-[48px] font-[400] mt-[20px] rounded-[8px] border-borderSecondary {
                ] text-[14px] focus:outline-none  focus:ring-transparent"
                  value={answer}
                  onChange={(e: any) => setAnswer(e?.target?.value)}
                />
              )}
            </div>
          </div>
          <div className="flex flex-col w-[80%] m-auto max-md:w-[100%] max-md:p-[20px]  max-[360px]:pt-0">
            <Button
              className="w-full text-white bg-primary rounded-[24px] mt-4 hover:opacity-90 hover:bg-primary"
              onClick={() => {
                if (context?.loginOption === SECURITY_CHALLENGE) {
                  context.setLoginOption("");
                  navigateToNextPage("", "/success", "/face-login");
                } else {
                  navigateToNextPage()
                }
                }}
              disabled={!answer}
            >
              {pageConfig?.buttonText || 'Save'}
            </Button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Challenge;

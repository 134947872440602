import StoreOsLayout from "../layout";
import scanner from "assets/scanner.svg";
import clockIcon from "assets/clock.svg";
import { useNavigation } from "utils/onNavigate";
import { ChevronLeft } from "lucide-react";
import { useNavigate } from "react-router-dom";
import StoreOsInput from "common/components/storeOsInput";
import { Button } from "components/ui/button";
import { useEffect, useState } from "react";

function StoreOsBarCode() {
  const { navigateToNextPage } = useNavigation();
  const navigate = useNavigate();
  const [isInfo, setIsInfo] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setIsInfo(!isInfo);
    }, 1000);
  }, []);

  const isBarcodeGenerated =
    localStorage.getItem("barcode-generated") === "true";

  const isLockedBarcode = localStorage.getItem("lockedBarcode") === "true";
  const onContinue = () => {
    navigateToNextPage("", "/store-os/pin", "/store-os/bar-code");
  };
  return (
    <StoreOsLayout isCard cardClassName="max-w-[900px]" isLockedBarcode={isLockedBarcode}>
      <div className="h-[73%] p-[20px]">
        <ChevronLeft
          className="h-[40px] w-[40px] ml-[-10px] cursor-pointer"
          onClick={() => navigate(-1)}
        />
        {isBarcodeGenerated ? (
          <div
            className={`bg-[#118738] mt-[-35px]  py-[20px] px-[20px] max-w-[400px] left-0 rounded-[8px] transition-all ease-in m-auto mb-[32px] ${
              isInfo ? "opacity-100" : "opacity-0"
            }`}
          >
            <p className={`text-left text-white text-[16px] font-[400]`}>
              <span className="font-[500]">Barcode Printed.</span> Scan your
              barcode to continue
            </p>
          </div>
        ) : (
          <div
            className={`bg-[#1171E6] mt-[-35px]  py-[20px] px-[20px] max-w-[542px] left-0 rounded-[8px] transition-all ease-in m-auto mb-[32px] ${
              isInfo ? "opacity-100" : "opacity-0"
            }`}
          >
            <p className={`text-left text-white text-[16px] font-[400]`}>
              <span className="font-[500]">Couldn't capture Facial Scan.</span>{" "}
              Please use options below to continue.
            </p>
          </div>
        )}

        <div className="flex px-[40px] py-[1rem]">
          <div className="w-[50%] py-[4rem] pb-[5rem] border-r border-r-1 border-r-[#DBD8D5] pr-[30px]">
            <img
              src={scanner}
              alt="scanner"
              className="w-[140px] h-[140px] m-auto"
            />
            <p className="mt-[0px] text-[25px] text-[#1E2032] font-[500]">
              Already have a barcode?
            </p>
            <h4 className="mt-[-10px] text-[40px] text-[#1E2032] font-[500]">
              Scan Barcode{" "}
            </h4>
          </div>
          <div className="w-[50%] pl-[30px]">
            <p className="mt-[0px] text-[23px] text-[#1E2032] font-[500] text-left">
              Don’t have a barcode?
              <br />
              Print Barcode
            </p>
            <div className="w-full h-[3px] bg-[#FAF9FA] my-[10px]" />
            <form className="mt-[15px] text-left">
              <StoreOsInput label="Employee ID" />
              <StoreOsInput label="Password" type="password" />
              <span className="text-[15px] font-[400]  text-[#004D99] cursor-pointer mt-[-15px] block">
                Change password
              </span>
              <Button
                className="w-full text-[#1A4C92] bg-[#EDF1F5] rounded-[24px] mt-4 hover:opacity-90 hover:bg-primary hover:text-white"
                onClick={() => onContinue()}
              >
                Continue
              </Button>
            </form>
          </div>
        </div>
      </div>
      {!isLockedBarcode && (
        <div className="max-w-[800px] m-auto fixed left-[50%] translate-x-[-50%] bottom-[10px] w-full flex justify-center items-center flex-col">
          <div className="mb-[30px] flex justify-center items-center">
            <button className="border border-[#262626] px-[26px] py-[10px] text-[16px] text-[#262626] font-[500] rounded-[60px] flex items-center justify-center mr-[32px]">
              <img src={clockIcon} alt="shieldSetting" className="mr-[8px]" />
              Time In/Out
            </button>
          </div>
          <p className="text-center text-[#575757] text-[15px] font-[400]">
            Note: You are responsible for all activities performed at a
            workstation while logged in. Use the F10 key to log out when
            stepping away from your workstation to prevent unauthorized use of
            the workstation by another colleague.
          </p>
        </div>
      )}
    </StoreOsLayout>
  );
}

export default StoreOsBarCode;

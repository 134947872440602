import { yupResolver } from "@hookform/resolvers/yup";
import StoreOsButton from "common/components/storeOsButton";
import StoreOsInput from "common/components/storeOsInput";
import StoreOsToastSmall from "common/components/storeOsToastSmall";
import { ChevronLeft } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { formSchemaSignup } from "schema/form";
import { registerBiometric } from "services/storeOs";
import { useNavigation } from "utils/onNavigate";
import StoreOsLayout from "../layout";

function SignUp() {
  const { navigateToNextPage } = useNavigation();
  const navigate = useNavigate();
  const [attempt, setAttempt] = useState(3);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(formSchemaSignup),
  });

  const onSubmitHandler = async (data: any) => {
    navigateToNextPage("", "/store-os/user-consent", "/store-os/signin");

    // if (attempt === 0) {
    //   // localStorage.setItem("locked", "true");
    //   navigateToNextPage("", "/store-os/user-consent", "/store-os/signin");
    //   // navigateToNextPage("", "/store-os/landing", "/store-os/signin");
    // }
    // setAttempt(attempt - 1)
    // try {
    //   const payload = {
    //     employeeId: data?.employeeId,
    //     password: data?.password,
    //     correlationId: "442asdfasd4asd",
    //   };
    //   const result = await registerBiometric(payload);
    //   console.log(result, "result");
    //   // navigateToNextPage("", "/store-os/user-consent", "/store-os/signin");
    // } catch (error) {
    //   console.log(error, "error");
    // }

    // console.log(data, "data");
  };
  return (
    <StoreOsLayout isCard>
      <div className="h-[70%] p-[48px] w-full max-[360px]:p-[20px] max-md:p-[20px]">
        <div className="flex items-center relative">
          <ChevronLeft
            className="h-[45px] w-[45px] mr-[5px] cursor-pointer  max-md:h-[30px]  max-md:w-[30px]"
            onClick={() => navigate(-1)}
          />
          <p className="text-[35px] font-[500] text-black max-md:text-[20px]">
            Register for Facial Scan
          </p>
        </div>
        <div className="w-full h-[3px] bg-[#FAF9FA] my-[20px]" />

        <form
          className="w-full mt-[0px] text-left"
          onSubmit={handleSubmit(onSubmitHandler)}
        >
          <StoreOsInput
            label="Employee ID"
            register={{ ...register("employeeId") }}
            name="employeeId"
            error={errors.employeeId?.message}
            value={"UserRXDtest38"}
          />
          <StoreOsInput
            label="Password"
            type="password"
            register={{ ...register("password") }}
            name="password"
            error={errors.password?.message}
            value={"La8223#@"}
          />
          <span className="text-[15px] font-[400]  text-[#004D99] cursor-pointer">
            Change password
          </span>
          <StoreOsButton
            title="Continue"
            className="w-full mt-[50px]"
            type="submit"
          />
        </form>
      </div>
      
      <div className="fixed ml-[10px] mt-[-20px]">
      <StoreOsToastSmall
          title={'You have 1 attempt left'}
          description={'For security reasons your account will be locked if you enter the wrong information again'}
          visible={attempt === 0}
          isError={true}
        />
      </div>
    </StoreOsLayout>
  );
}

export default SignUp;

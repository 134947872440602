import { ChevronLeft } from "lucide-react";
import { useEffect, useState } from "react";
import board from "assets/board.svg";
import Wifi from "assets/wifi.svg";
import GuestLogin from "assets/guestLogin.svg";
import editIcon from "assets/editIcon.svg";

import { useNavigate } from "react-router-dom";
import { useNavigation } from "utils/onNavigate";
import StoreOsLayout from "../layout";

function StoreOsOtherOptions() {
  const { navigateToNextPage } = useNavigation();
  const navigate = useNavigate();
  const [isError, setIsError] = useState(false);
  const [password, setPassword] = useState("");

  const onContinue = () => {
    if (password !== "9999") {
      setIsError(true);
      return;
    }
    navigateToNextPage("", "/store-os/lock-screen", "/store-os/signin");
  };

  const onChange = (event: any) => {
    const { type, value } = event?.target;
    if (type === "password") {
      setPassword(value);
    }
  };

  const OPTIONS = [
    {
      label: "Manager Override",
      icon: board,
    },
    {
      label: "Clinic Mode",
      icon: Wifi,
    },
    {
      label: "Guest Login",
      icon: GuestLogin,
    },
    {
      label: "Register for Facial Scan",
      icon: editIcon,
    },
  ];
  return (
    <StoreOsLayout className="mt-[4rem]">
      <div className="p-[48px] w-full max-[767px]:p-[30px]">
        <div className="flex items-center relative">
          <ChevronLeft
            className="h-[35px] w-[35px] mr-[5px] cursor-pointer max-[767px]:ml-[-10px]"
            onClick={() => navigate(-1)}
          />
          <p className="text-[35px] font-[500] text-black max-[767px]:text-[22px]">
            Back
          </p>
        </div>
        {OPTIONS?.map((option) => (
          <div
            className={`border border-border rounded-[24px] cursor-pointer bg-white text-center innerCardStore w-full px-[36px] py-[24px] mt-[20px] flex items-center`}
          >
            <img src={option?.icon} alt={option?.label} className="mr-[15px]" />
            <p className="text-left">{option?.label}</p>
          </div>
        ))}
      </div>
    </StoreOsLayout>
  );
}

export default StoreOsOtherOptions;

import shieldSetting from "assets/shieldSetting.svg";
import storeOsLogo from "assets/storeOSLogo.png";
import bgOs from "assets/bgOs.png";

function StoreOsLayout(props: any) {
  const {
    children,
    landingScreen,
    isCard,
    className,
    onClick,
    cardClassName,
    isLockedBarcode,
  } = props;
  return (
    <div
      className="bg-[#F7F7F7] h-[100vh] max-[430px]:h-[calc(100vh_-80px)]"
      style={{
        backgroundImage: isLockedBarcode ? `url(${bgOs})` : "",
        backgroundSize: isLockedBarcode ? "100% 100%" : "",
        paddingTop: isLockedBarcode ? "4rem" : ''
      }}
    >
      {!isLockedBarcode && (
        <div
          className={`${landingScreen ? "" : "bg-[#fff]"} py-[15px] px-[24px]`}
        >
          {landingScreen ? (
            <div className="flex justify-end w-full">
              <button
                className="border border-[#CFCECE] px-[16px] py-[9px] text-[16px] text-[#575757] font-[500] rounded-[60px] flex items-center justify-center bg-white"
                onClick={onClick}
              >
                <img
                  src={shieldSetting}
                  alt="shieldSetting"
                  className="mr-[8px]"
                />
                Admin
              </button>
            </div>
          ) : (
            <div className="flex justify-center items-center w-full py-[6px]">
              <img src={storeOsLogo} alt="storeOsLogo" className="w-[110px]" />
            </div>
          )}
        </div>
      )}
      {isCard ? (
        <div
          className={`flex justify-center items-center w-full max-w-[720px] py-[35px] px-[60px] m-auto max-[767px]:px-[16px] ${cardClassName}`}
        >
          <div
            className={`border border-border rounded-[24px] bg-white text-center innerCardStore w-full ${className}`}
          >
            {children}
          </div>
        </div>
      ) : (
        children
      )}
    </div>
  );
}

export default StoreOsLayout;

import {
  authenticateBarcodePayload,
  authenticateBiometricCredentialsPayload,
  authenticateBiometricPayload,
  authenticateCredentialsPayload,
  registerBarcodePayload,
  registerBiometricPayload,
  registerPayload,
  registerTempBiometricPayload,
} from "constant/storeOsTypes";

import axios from "axios";

const axiosInt = axios.create({
  baseURL: "https://api-develv3.cvsauth.com/node",
  headers: {
    "x-api-key": process.env.REACT_APP_API_KEY ?? "0000000000000000test",
  },
});

axiosInt.interceptors.request.use(
  async (config) => {
    config.headers.authorization = "";
    return Promise.resolve(config);
  },
  (error) => Promise.reject(error)
);

axiosInt.interceptors.response.use(
  (response) => response.data,
  (error) => {
    return Promise.reject(error.response);
  }
);

export const registerStoreOs = async (payload: registerPayload) => {
  try {
    const response = await axiosInt.post(`/storeOsAgent/FACE3_1/register`, {
      ...payload,
    });
    return response;
  } catch (e) {
    return e;
  }
};

export const authenticateStoreOs = async (payload: registerPayload) => {
    try {
      const response = await axiosInt.post(`/storeOsAgent/FACE3_1/authenticate`, {
        ...payload,
      });
      return response;
    } catch (e) {
      return e;
    }
  };

export const registerBiometric = async (payload: registerBiometricPayload) => {
  try {
    const response = await axiosInt.post(`/register/biometric`, {
      payload,
    });
    return response;
  } catch (e) {
    return e;
  }
};

export const registerBarcode = async (payload: registerBarcodePayload) => {
  try {
    const response = await axiosInt.post(`/register/barcode`, {
      payload,
    });
    return response;
  } catch (e) {
    return e;
  }
};

export const registerTempBiometric = async (
  payload: registerTempBiometricPayload
) => {
  try {
    const response = await axiosInt.post(`/register/temp-biometric`, {
      payload,
    });
    return response;
  } catch (e) {
    return e;
  }
};

export const authenticateBiometric = async (
  payload: authenticateBiometricPayload
) => {
  try {
    const response = await axiosInt.post(`/authenticate/biometric`, {
      payload,
    });
    return response;
  } catch (e) {
    return e;
  }
};

export const authenticateCredentials = async (
  payload: authenticateCredentialsPayload
) => {
  try {
    const response = await axiosInt.post(`/authenticate/credentials`, {
      payload,
    });
    return response;
  } catch (e) {
    return e;
  }
};

export const authenticateBiometricCredentials = async (
  payload: authenticateBiometricCredentialsPayload
) => {
  try {
    const response = await axiosInt.post(`/authenticate/biometricCredentials`, {
      payload,
    });
    return response;
  } catch (e) {
    return e;
  }
};

export const authenticateBarcode = async (
  payload: authenticateBarcodePayload
) => {
  try {
    const response = await axiosInt.post(`/authenticate/barcode`, {
      payload,
    });
    return response;
  } catch (e) {
    return e;
  }
};

export const healthCheck = async (payload: any) => {
  try {
    const response = await axiosInt.post(`/healthCheck`, {
      payload,
    });
    return response;
  } catch (e) {
    return e;
  }
};

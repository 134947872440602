import { InfoCircledIcon } from "@radix-ui/react-icons";
import { ShieldAlert } from "lucide-react";


function StoreOsToast({title, isError}: {title: string; isError?: boolean}) {
  return (
    <div className={`${isError ? 'bg-[#FFF2F2]' : 'bg-[#CAD5E4]'}  py-[16px] px-[24px] fixed top-[70px] w-full left-0 flex items-center justify-between`}>
        <p className={`text-left ${isError ? 'text-[#DB3321]' : 'text-[#1A4C92]'} text-[14px] font-[400]`}>{title}</p>
        {
            isError ? <ShieldAlert color="#DB3321" className="w-[20px] h-[20px] cursor-pointer" /> :<InfoCircledIcon color="#1A4C92" className="w-[20px] h-[20px] cursor-pointer" />
        }
        
    </div>
  );
}

export default StoreOsToast;

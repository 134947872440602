import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "components/ui/select";
import { FooterItems, languageCodes } from "constant";
import { UserContext } from "context/userContext";
import { useContext, useState } from "react";
import { getOrganizationConfig } from "services/api";
import { extractRoute, getOrganizationFromURL } from "utils";
import LivenessSwitch from "../livenessSwitch";

const Footer = () => {
  const {
    setConfiguration,
    setPageConfiguration,
    pageIndex,
    configuration,
  }: any = useContext(UserContext);
  const [language, setLanguage] = useState("en");
  const onLanguageChange = async (language: string) => {
    const organization = getOrganizationFromURL();
    setLanguage(language);
    const organizationConfig: any = await getOrganizationConfig(
      organization,
      language
    );
    if (organizationConfig?.name) {
      setConfiguration(organizationConfig);
      const pageConfiguration = extractRoute(organizationConfig, pageIndex);
      setPageConfiguration(pageConfiguration);
    } else {
      setConfiguration({});
    }
  };
  return (
    <div
      className={`flex justify-center m-auto ${
        configuration?.bgColor
          ? "mt-[-40px] max-[1600px]:mt-[-46px]"
          : "max-[1600px]:mt-[-20px]"
      } max-md:mt-[10px]  max-md:mb-[10px] max-w-[600px] w-[100%]`}
    >
      <ul className="flex justify-between w-[100%] max-md:w-[80%] px-[2rem] items-center max-md:px-0">
        <li className="text-secondaryText text-[14px] cursor-pointer">
          <Select
            onValueChange={(e: string) => onLanguageChange(e)}
            value={language}
          >
            <SelectTrigger
              className="h-[30px] mt-[12px] font-[400] rounded-[8px] border-borderSecondary {
                    ] text-[14px] focus:outline-none  focus:ring-transparent mt-[0px] w-[150px]  max-md:w-[100px]  max-[360px]:w-[85px]"
              style={{ color: language ? "#020817" : "#9ba3b1" }}
            >
              <SelectValue placeholder="Salect language" />
            </SelectTrigger>
            <SelectContent>
              {Object.entries(languageCodes).map(([key, value]) => {
                return (
                  <SelectItem key={key} value={value}>
                    {key}
                  </SelectItem>
                );
              })}
            </SelectContent>
          </Select>
        </li>
        {(configuration?.footer?.length > 0
          ? configuration?.footer
          : FooterItems
        )?.map((footer: any, index: number) => (
          <li
            className="text-secondaryText text-[14px] cursor-pointer"
            key={index}
          >
            <a target="_blank" href={footer?.link} rel="noreferrer">
              {footer?.label}
            </a>
          </li>
        ))}
        <LivenessSwitch liveness={configuration?.liveness} />
      </ul>
    </div>
  );
};

export default Footer;

import StoreOsButton from "common/components/storeOsButton";
import StoreOsInput from "common/components/storeOsInput";
import StoreOsToast from "common/components/storeOsToast";
import { ChevronLeft, Loader2 } from "lucide-react";
import { useEffect, useState } from "react";
import clockIcon from "assets/clock.svg";
import { useNavigate } from "react-router-dom";
import { useNavigation } from "utils/onNavigate";
import StoreOsLayout from "../layout";
import { authenticateStoreOs } from "services/storeOs";
import { embeddings } from "constant";
import { isMobile } from "utils";

function SignIn() {
  const { navigateToNextPage } = useNavigation();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [isError, setIsError] = useState(false);
  const [password, setPassword] = useState("");

  const onContinue = async () => {
    // if (password !== "9999") {
    //   setIsError(true);
    //   return;
    // }
    setLoader(true);
    const payload = {
      api_key: "store4cca2ffae08ff3a",
      employeeId: "UserRXDtest38",
      password: "La8223#@",
      embeddings: embeddings,
    };
    const result: any = await authenticateStoreOs(payload);
    setLoader(false);
    if (isMobile && result?.uuid) {
      navigateToNextPage("", "/store-os/lock-screen", "/store-os/signin");
    } else if (result?.uuid) {
      navigateToNextPage("", "/store-os", "/store-os/signin");
    }
  };

  const onChange = (event: any) => {
    const { type, value } = event?.target;
    if (type === "password") {
      setPassword(value);
    }
  };
  return (
    <StoreOsLayout isCard className="mt-[4rem] max-[360px]:mt-[0px]  max-[767px]:mt-[0px]">
      {!isMobile && (
        <StoreOsToast
          title={
            isError
              ? "Please register for Facial Scan to sign in."
              : "Please sign in with your Employee ID and Password for your first sign in of the day."
          }
          isError={isError}
        />
      )}
      <div className="h-[70%] p-[48px] w-full max-[767px]:p-[30px] max-[360px]:p-[20px] max-[360px]:h-[unset]">
        <div className="flex items-center relative max-[767px]:justify-center">
          <ChevronLeft
            className="h-[45px] w-[45px] mr-[5px] cursor-pointer max-[767px]:hidden"
            onClick={() => navigate(-1)}
          />
          <p className="text-[35px] font-[500] text-black max-[767px]:text-[25px]">
            Sign In
          </p>
        </div>
        <div
          className={`w-full h-[3px] bg-[#FAF9FA] my-[20px] max-[767px]:my-[15px]`}
        />
        <form
          className="w-full mt-[0px] text-left"
          onSubmit={(e) => e?.preventDefault()}
        >
          <StoreOsInput label="Employee ID" value={"UserRXDtest38"} />
          <StoreOsInput
            label="Password"
            type="password"
            onChange={onChange}
            value={"La8223#@"}
          />
          <span className="text-[15px] font-[400]  text-[#004D99] cursor-pointer">
            Change password
          </span>
          <StoreOsButton
            title={
              loader ? (
                <Loader2 className="animate-spin h-[24px] w-[24px] text-[#fff] m-auto" />
              ) : (
                "Continue"
              )
            }
            className="w-full mt-[50px] max-[767px]:mt-[20px]  max-[360px]:min-w-[100%]"
            onClick={onContinue}
            disabled={loader}
          />
        </form>
      </div>
      <div className="mb-[50px] max-[360px]:relative justify-center items-center max-[767px]:flex-col max-[767px]:mb-[20px] hidden max-[767px]:flex fixed mt-3 w-full left-0 bottom-2  max-[767px]:relative">
        <button
          onClick={() =>
            navigateToNextPage("", "/store-os/facial-scan", "/store-os/landing")
          }
          className="border border-[#262626] max-w-[174px] w-full max-[767px]:mb-[10px] px-[26px] py-[10px] text-[16px] text-[#262626] font-[500] rounded-[60px] flex items-center justify-center mr-[32px] max-[767px]:mr-0"
        >
          <img src={clockIcon} alt="shieldSetting" className="mr-[8px]" />
          Time In/Out
        </button>
        <button
          onClick={() =>
            navigateToNextPage(
              "",
              "/store-os/other-options",
              "/store-os/landing"
            )
          }
          className="border border-[#262626] max-w-[174px] w-full px-[26px] py-[10px] text-[16px] text-[#262626] font-[500] rounded-[60px] flex items-center justify-center"
        >
          Other Options
        </button>
      </div>
    </StoreOsLayout>
  );
}

export default SignIn;

import BackButton from "common/components/backButton";
import { UserContext } from "context/userContext";
import { useContext, useEffect, useState } from "react";
import StoreOsLayout from "../layout";
import lock from "assets/lock.svg";
import CameraComponent from "common/components/camera";
import useEnrollOneFaWithLiveness from "hooks/useEnrollOneFaWithLiveness";
import {
  closeCamera,
  convertCroppedImage,
  updateTypeEnum,
  updateUserWithSession,
  uploadEnrollImageWithSession,
} from "@privateid/cryptonets-web-sdk";
import { ELEMENT_ID } from "constant";
import { useNavigation } from "utils/onNavigate";
import { useNavigateWithQueryParams } from "utils/navigateWithQueryParams";
import { Label } from "components/ui/label";

function FacialScan({ isLogin }: any) {
  const context = useContext(UserContext);
  const { navigateToNextPage } = useNavigation();
  const { navigateWithQueryParams } = useNavigateWithQueryParams();
  const [scanCompleted, setScanCompleted] = useState(false);
  const configuration: any = context?.configuration;
  const pageConfig: any = context?.pageConfiguration;
  const {
    enrollGUID,
    enrollPUID,
    enrollStatus,
    enrollValidationStatus,
    enrollUserOneFa,
    enrollImageData,
    progress,
  }: any = useEnrollOneFaWithLiveness(() => {});
  useEffect(() => {
    if (enrollImageData && enrollGUID) {
      onFaceSuccess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enrollImageData, enrollGUID]);

  const alreadyEnrolled = enrollValidationStatus === "User Already Enrolled";

  const onFaceSuccess = async () => {
    setScanCompleted(true);
    context.setUser({
      ...context.user,
      uuid: enrollPUID,
      guid: enrollGUID,
      enrollImageData: enrollImageData,
      alreadyEnrolled,
    });
    if (context?.isGenerateUuid || !isLogin) {
      localStorage.setItem("uuid", JSON.stringify(enrollPUID || {}));
      return;
    }
    await updateUserWithSession({
      sessionToken: context?.tokenParams,
      updateType: updateTypeEnum.enroll,
      uuid: enrollPUID,
      guid: enrollGUID,
    });

    if (enrollImageData) {
      const enrollPortraitBase64 = await convertCroppedImage(
        enrollImageData.data,
        enrollImageData.width,
        enrollImageData.height
      );
      if (configuration?.uploadToServer) {
        await uploadEnrollImageWithSession({
          sessionToken: context?.tokenParams,
          imageString: enrollPortraitBase64,
        });
      }
      if (!isLogin) {
        localStorage.setItem("uuid", JSON.stringify(enrollPUID || {}));
      }
    }
  };
  const onSuccess = () => {
    setTimeout(async () => {
      await closeCamera(ELEMENT_ID);
      if (context?.isFaceScan) {
        context?.setIsFaceScan(false);
        if (isLogin) {
          navigateToNextPage("", "/store-os/signin", "/face-scan");
        } else {
          navigateToNextPage("", "/store-os/waiting", "/face-scan");
        }
      } else if (pageConfig) {
        if (isLogin) {
          navigateToNextPage("", "/store-os/signin", "/face-scan");
        } else {
          navigateToNextPage("", "/store-os/waiting", "/face-scan");
        }
      } else {
        if (isLogin) {
          navigateToNextPage("", "/store-os/signin", "/face-scan");
        } else {
          navigateToNextPage("", "/store-os/waiting", "/face-scan");
        }
      }
    }, 2000);
  };
  const onCameraReady = () => {
    enrollUserOneFa("", false, "collection_d", "test");
  };
  return (
    <StoreOsLayout isCard>
      <div className="h-[70%]">
        <div className="px-10 py-8 max-md:p-[20px] max-md:pt-[20px]">
          <div className="flex justify-between relative">
            <BackButton isCamera />
            <div className="bg-backgroundLightGray py-[5px] px-[15px] text-[12px] rounded-[20px] flex items-center m-auto">
              <img src={lock} alt="lock" className="mr-[5px]" />
              Image will never leave this device
            </div>
          </div>
          <div className="mt-[50px] rounded-[20px] flex flex-col items-center justify-center max-[360px]:mt-[30px]">
            <CameraComponent
              faceCamera={true}
              onCameraReady={onCameraReady}
              progress={progress}
              message={enrollValidationStatus}
              onSuccess={onSuccess}
              attempt={enrollStatus || enrollStatus === 0 ? 1 : 0}
              scanCompleted={scanCompleted}
              isStoreOs={true}
            />
          </div>
        </div>
        {/* <div className="mt-[-40px] mb-[25px]">
          <div className="text-primary text-[14px] cursor-pointer block text-center mb-0 mt-[0px] max-lg:hidden">
            <Label className="font-[400] text-secondaryText text-[14px]">
              {pageConfig?.havingText || "Having problems?"}
            </Label>
            <Label
              className="font-[500] text-primary ml-[6px] cursor-pointer text-[14px] hover:underline"
              onClick={async () => {
                await closeCamera(ELEMENT_ID);
                navigateToNextPage(
                  "",
                  "/store-os/bar-code",
                  "/store-os/facial-scan"
                );
              }}
            >
              {pageConfig?.switchText || "Switch to mobile"}
            </Label>
          </div>
        </div> */}
      </div>
    </StoreOsLayout>
  );
}

export default FacialScan;

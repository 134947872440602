import * as yup from "yup";

export const formSchema = yup.object().shape({
  email: yup.string().email(),
  ssn4: yup.string(),
  phone: yup.number().min(10).max(15),
});



export const formSchemaSignup = yup.object().shape({
  employeeId: yup.string().required('Enter a valid Employee ID'),
  password: yup.string().required('Invalid password. Check your spelling and try again.'),
});
import StoreOsLayout from "../layout";
import { ChevronLeft } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { Button } from "components/ui/button";
import StoreOsOtpInput from "common/components/storeOsOtpInput";
import { useNavigation } from "utils/onNavigate";

function StoreOsPin() {
  const navigate = useNavigate();
  const {navigateToNextPage} = useNavigation();
  const isBarcodeGenerated =
    localStorage.getItem("barcode-generated") === "true";
  const isLockedBarcode = localStorage.getItem("lockedBarcode") === "true";

  const onContinue = () => {
    if (isBarcodeGenerated) {
      localStorage.removeItem("lockedBarcode");
      localStorage.removeItem("barcode-generated");
      navigateToNextPage('', "/store-os", '/store-os/pin');
    } else {
      localStorage.setItem("barcode-generated", "true");
      navigate("/store-os/bar-code");
    }
    
  };
  return (
    <StoreOsLayout isCard className="max-w-[500px]" isLockedBarcode={isLockedBarcode}>
      <div className="h-[73%] p-[30px]">
        <div className="flex items-center">
          <ChevronLeft
            className="h-[30px] w-[30px] ml-[-10px] cursor-pointer"
            onClick={() => navigate(-1)}
          />
          <p className="text-[20px] font-[500] text-black">
            {isBarcodeGenerated ? "Enter your PIN" : "Print Barcode"}
          </p>
        </div>
        <div className="w-full h-[3px] bg-[#FAF9FA] my-[10px]" />
        <div className="flex">
          <div className="w-[100%]">
            <form className="mt-[15px] text-left">
              <StoreOsOtpInput label="Create a 4 digit PIN" />
              {!isBarcodeGenerated && (
                <div className="mt-[20px] mb-[30px]">
                  <StoreOsOtpInput label="Confirm PIN" />
                </div>
              )}

              <Button
                className="w-full text-white bg-primary rounded-[24px] mt-4 hover:opacity-90 hover:bg-primary hover:text-white"
                onClick={() => onContinue()}
              >
                Continue
              </Button>
            </form>
          </div>
        </div>
      </div>
    </StoreOsLayout>
  );
}

export default StoreOsPin;
